#bottom-overlay {
    display: none; // Changes to display: flex when scrolling past the toggle point specified within the bottom-overlay script
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    position: fixed;
    bottom: 0px;
    right: 0px;

    width: 100%;
    padding: 5px 0px;

    background: $gradientBlack;

    z-index: 10;

    button {
        margin: 0px 8px;
        padding: 5px 0px;

        width: 110px;
        height: auto;

        background: -webkit-gradient(linear, left bottom, left top, from($fspsRed), to(lighten($fspsRed, 18)));

        background: linear-gradient(to top, $fspsRed, lighten($fspsRed, 18));

        color: white;
        font-weight: bold;
        font-size: 20px;
        border-radius: 1.0em;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;

        &:active {
            background: -webkit-gradient(linear, left top, left bottom, from($fspsRed), to(darken($fspsRed, 10)));
            background: linear-gradient(to bottom, $fspsRed, darken($fspsRed, 10));
        }
    }

    @media screen and (max-width: #{$menuIconBP}),
    screen and (max-height: #{$landscapeBP}) {
        button {
            margin: 0px 4px;
            font-size: 18px;
        }

    }
}