#top-overlay {
    position: fixed;
    top: 0px;

    width: 100vw;
    height: $topBarHeight;

    @media screen and (max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        height: $topBarHeight / 2;
    }

    @media screen and (max-width: #{$logoBP1}) {
        height: 70px;
    }

    z-index: 4;

    background: rgba(251, 251, 248, 1.0);
    background-image: $gradient2;
    -webkit-box-shadow: $boxShadow;
            box-shadow: $boxShadow;


    #overlay-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;

        height: 100%;

        padding: 0px 10px;

        @import 'logo';
        @import 'menu';
    }
}