#logo-wrapper {
    position: relative;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    width: 50%;
    z-index: 10;

    #logo {
        position: absolute;
        top: 0px;
        margin-left: calc(50% - (#{$logoSize} / 2));

        cursor: pointer;
        overflow: hidden; //Chop off the edges around the logo image so it becomes a circle

        img {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .large {
        background-color: white;

        padding: 0px;
        width: $logoSize;
        height: $logoSize;

        border: $borderStyle;
        border-radius: 30%;
        -webkit-box-shadow: $circleShadow;
                box-shadow: $circleShadow;

    }

    .small {
        width: 162px;
        height: 70px;
    }

    @media screen and (max-width: $iPad) {
        width: 45%;
        .large {
            width: 275px;
            height: 275px;
        }
    }

    // At or below 745px wide or 480px tall, 
    // logo size is reduced to half
    @media screen and (max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        
        width: $logoSize / 2;

        #logo {
            margin-left: 0px;
        }

        .large {
            padding: 0px;
            width: $logoMobile;
            height: $logoMobile;
        }
    }

    // Smallest width - the 'large' class is not used, everything is toggled
    @media screen and (max-width: #{$logoBP1}) {
        width: 75px;
    }
}