#nav-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;

    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    width: 50%;
    height: 100%;

    @import 'menu_icon';

    #menu-icon {
        display: none;
    }

    #menu-phone {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        
        padding-right: 10px;

        @media screen and (min-width: 570px) {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        
        width: 100%;
        padding-right: 22px;

        }
    }

    #site-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .siteLink {
            margin: 3px 12px 2px 12px;

            color: $fspsCharcoal;
            font-size: 20px;
            text-align: center;
            font-weight: bold;
            text-decoration: none;
            white-space: nowrap;

            cursor: pointer;

            &:hover {
                color: $fspsRed;
            }
        }
    }

    // At or below 745px wide or 480px tall, 
    // logo decreases so nav menu width needs to be adjusted
    @media screen and(max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        width: calc(100% - (#{$logoSize} / 2.0));

        #site-links {
            .siteLink {
                margin: 8px;
            }
        }
    }

    // At or below 570px wide, hide the site links and reveal the menu icon button.
    // Site links are revealed via the menu icon button
    @media screen and (max-width: #{$menuIconBP}) {
        position: relative;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;

        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        
        // justify-content: space-between;

        // overflow: visible;

        #menu-icon {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            margin-top: 5px;
             
        }

        #site-links {
            position: absolute;
            top: $topBarHeight / 2;

            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;

            -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;

            height: 0px;
            display: none;
       
            background: rgb(44, 41, 41);
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;

            .siteLink {
                margin: 10px 10px;
                padding: 5px;
                width: 120px;
                border-radius: 30px;
                background: -webkit-gradient(linear, left top, left bottom, from(rgba(251, 251, 248, 1.0)),to(rgba(46, 37, 37, 0.9)));
                background: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(46, 37, 37, 0.9));
                -webkit-box-shadow: $boxShadow;
                box-shadow: $boxShadow;

                &.active {
                    background: $gradientReverse;
                }

            }

        }

    }



    // Smallest width - the 'large' class is not used, everything is toggled
    @media screen and (max-width: #{$logoBP1}) {
        width: calc(100% - 75px);

        #menu-icon {
            -webkit-transform: translateX(calc((100vw - 75px - 20px) / 2 - 20px));
            transform: translateX(calc((100vw - 75px - 20px) / 2 - 20px));
        }

        #site-links {
            top: 70px;
            -webkit-transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px));
            transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px));
        }
    }
}


/* ----------------------------------- Menu Dropdown --------------------------*/



.dropdown {

    @media screen and (max-width: #{$menuIconBP}) {    
        display: -webkit-box;    
        display: -ms-flexbox;    
        display: flex;

        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        position: relative;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;

        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
    }
}


//* Turns menu page siteLinks into transparent button and permits drop down menu of subpages
.dropbtn {
    background: none;
    color: $fspsCharcoal;
    padding: 0px;
    font-size: 16px;
    border: none;
    
}

//* The dropdown menu box on full-screen. Only appears w/ hover
.dropdown-content {
    display: none; // this keeps box from appearing before hover opens it
    position: absolute;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(46, 37, 37, 0.0)), color-stop(5%, rgb(248, 248, 244)), to(rgb(245, 240, 240)));
    background-image: linear-gradient(to bottom, rgba(46, 37, 37, 0.0), rgb(248, 248, 244) 5%, rgb(245, 240, 240));
    border-left: 3px solid $fspsRedlow;
    border-bottom: 3px solid $fspsRed;
    border-right: 3px solid $fspsRedlow;
    border-radius: 0px 30px 10px 15px;
    min-width: 140px;

    

    z-index: 0;

    li {
        list-style-type: none;
        text-align: left;
        font-size: .80em;
    }

    @media screen and (max-width: #{$menuIconBP}) {
        border-radius: 0px 0px 30px 30px;
        top:0px;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;

        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;

        right: 100%;
        padding-top: 50px;
        padding-bottom: 10px;
        position: absolute;
        background: rgba(0, 0, 0, .9);
        width: 130px;

        li {
            height: 44px;
            text-align: center;
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(251, 251, 248, 1.0)),to(rgba(46, 37, 37, 0.9)));
            background: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(46, 37, 37, 0.9));
            border: solid rgb(31, 30, 30);
            border-radius: 30px;
            margin: 20px 5px;
        }

    }

    
}

//* The link design for the pages in the dropdown menu
.dropdown-content a {
    color: $fspsCharcoal;
    border-radius: $borderRadius2;
    padding: 12px 12px;
    text-decoration: none;
    display: block;

}

//* The changes to the link design for dropdown menu pages as result of hover
.dropdown-content a:hover {
    background-color: $fspsCharcoallow;
    color: $fspsRed;
}

//* On the menu hover over page w/ subpages and content displays
.siteLink.dropbtn.subPbtn:hover .dropdown-content {
    display: block;
}

