#home-content-wrapper {
     

    #description {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column nowrap;
                flex-flow: column nowrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        padding: 50px 10px;
        height: 350px;

        background-color: $fspsRedlow;
        text-shadow: $textShadow1;

        h2 {
            text-align: center;
            font-size: 32px;
            color: white;
        }

        a {
            color: $fspsRed;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        p {
            color: whitesmoke;
            max-width: 900px;
            text-align: center;
            font-size: 20px;
        }

        @media screen and (max-width: 770px) {
            height: 500px;
        }
    }

    #video-area {
        padding: 40px;
        width: 100%;

        background-color: $fspsRed;

        #video-wrapper {
            margin: 0px auto;

            width: 100%;
            max-width: (960px + ($borderWidth * 2)); // Video Size + Border
            height: 100%; // 540px + borders

            border: $borderStyle;

            video {
                display: block;
                width: 100%;
                outline: none;
            }
        }
    }

    #mission-statement {
        margin: 80px auto;
    }

    h2 {
        margin: 20px auto;
        padding: 0px 40px;
        text-align: center;
        font-size: 32px;

        @media screen and (max-width: #{$logoBP2}) {
            font-size: 24px;
        }
    }

    p {
        font-size: 24px;
        margin: 20px auto;
        padding: 0px 40px;
    }

    @media screen and (max-width: #{$menuIconBP}),
    screen and (max-height: #{$landscapeBP}) {

        #description {
            h2 {

                font-size: 24px;
            }
        }

        #video-area {
            padding: 20px;
        }

        #mission-statement {
            margin: 40px auto;
        }
    }

    // When the screen gets too narrow, allow the description
    // area height to be flexible.
    @media screen and (max-width: 270px) {
        #description {
            height: 100%;
        }
    }
}
