.grid {display: grid;}
.grid-box {
    background-color: rgba(39, 39, 39, .9);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(50px, auto));
    gap: 0 1rem;
    padding: 50px 20px 60px 20px;
    margin: 60px 50px 20px 50px;
    border-radius:5px;
    @media screen and (max-width:767px) {
        margin: 40px 15px 20px 15px;
        gap: 0;
    }
}
.err {
    padding: .75rem 15%;
    color: rgba(0, 0, 0, .8);
    @media screen and (min-width:415px) and (max-width:765px) {
        padding: .75rem .25rem;
    }
}

.err-header {
    grid-column: 1 / span 2;
    text-align: center;
    font-size: 1.50em;
    letter-spacing: .25em;
    padding: 2rem 12%;
    background-color: rgb(255, 255, 248);
    border:6px solid $fspsRed;
    border-radius: 5px 5px 1px 1px;
    
    @media screen and (max-width:1578px) {
        line-height: 1.65em;
    }
    @media screen and (min-width:415px) and (max-width:765px) {
        max-width: 470px;
    }
    @media screen and (max-width:415px) {
        letter-spacing: .15em;
        font-size: 1.4em;
        padding: 1rem 1rem;
        grid-column: 1 / span 1;
    }
}

.flt-hdr {
    text-align: center;
    font-size: 1.5em;
    letter-spacing: .15em;
    border-bottom: solid 1px rgb(39, 39, 39);
}
.subg-wrppr {
    display:grid;
    grid-template-columns: subgrid;
    background-color: rgb(255, 255, 248);
    border:3px solid $fspsRed;
    border-radius: 5px;
}
.err-sltn-bckgrnd {
    background-image: url("/images/generators/error-code-grid/generator-error-code-solution-background.jpg");
    background-repeat: no-repeat;
    background-position: center;

}
.gap-mrgn {margin-top: 1rem;}
.causes {
    
    p {
        text-align: left;
        line-height: 1.35em;
    }
    p.r8t {
        font-size: 2em;
        letter-spacing: .15em;
        text-align: center;
        margin-top: 30px;
        @media screen and (max-width:800px) {
            font-size: 1.8em;
        }
    }
}
.espn2 {grid-column: 1 / span 2; -webkit-box-align: center; -ms-flex-align: center; align-items: center;}
.e1 {grid-column: 1 / span 1;}
.e2 {
    grid-column: 2 / span 1;
        @media screen and (max-width:500px) {
            grid-column: 1 / span 1;
        }
}
.frst {-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.scnd {-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.thrd {-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;
    @media screen and (max-width:500px) {
        -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
                order: 4;
    }
}
.frth {-webkit-box-ordinal-group: 5;-ms-flex-order: 4;order: 4;
    @media screen and (max-width:500px) {
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
    }
}
.ffth {-webkit-box-ordinal-group: 6;-ms-flex-order: 5;order: 5;
    @media screen and (max-width:500px) {
        -webkit-box-ordinal-group: 7;
            -ms-flex-order: 6;
                order: 6;
    }
}
.sxth {-webkit-box-ordinal-group: 7;-ms-flex-order: 6;order: 6;
    @media screen and (max-width:500px) {
        -webkit-box-ordinal-group: 8;
            -ms-flex-order: 7;
                order: 7;
    }
}
.svnth {-webkit-box-ordinal-group: 8;-ms-flex-order: 7;order: 7;
    @media screen and (max-width:500px) {
        -webkit-box-ordinal-group: 9;
            -ms-flex-order: 8;
                order: 8;
    }
}
.eghth {-webkit-box-ordinal-group: 9;-ms-flex-order: 8;order: 8;
    @media screen and (max-width:500px) {
        -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
                order: 9;
    }
}
.nnth {-webkit-box-ordinal-group: 10;-ms-flex-order: 9;order: 9;}

a.err-button{
    display:inline-block;
    padding:0.45em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    color:#FFFFFF;
    text-align:center;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    letter-spacing: .10em;
    }
    a.err-button:hover{
    color:#000000;
    background-color:#FFFFFF;
    }
    @media all and (max-width:30em){
     a.err-button{
        display:block;
        margin:0.4em auto;
    }
}

.btt-pd {
    padding: .75rem 40%;
    margin-top: 40px;
    @media screen and (max-width: 1230px) {padding: .75rem 20%;}
    @media screen and (min-width:415px) and (max-width:765px) {padding: .75rem 10%;}
    @media screen and (max-width: 414px) {padding: .75rem 5%;}
}