/* generators page */

.generators-bg {
    background-image: -webkit-gradient(linear,
        left top, left bottom,
        from(rgba(0, 0, 0, .1)),
        to(rgba(0, 0, 0, 0.5))), url('./images/generators/Generac-Transfer-Switch-background-image.jpg');
    background-image: linear-gradient(rgba(0, 0, 0, .1),
        rgba(0, 0, 0, 0.5)), url('./images/generators/Generac-Transfer-Switch-background-image.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center top;
}

#header-banner {
    height: calc(100vh - 180px);

    #banner-image-overlay {
        position: absolute;
        bottom: 0px;
        z-index: 0;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        padding: 0px 10px;
        width: 100%;
        min-height: 20%;

        overflow: hidden;

        background-color: rgba(0, 0, 0, 0.4);

        h1 {
            color: White;
            text-shadow: 2px 2px 5px black;

            font-size: 86px;
        }
    }



    @media screen and (max-width: #{$menuIconBP}),
    screen and (max-height: #{$landscapeBP}) {
        height: calc(100vh - 110px);
        
        #banner-image-overlay {
            h1 {
                font-size: 56px;
            }
        }
    }
}

#about-content-wrapper {
    
    #description {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        -webkit-box-orient: vertical;

        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        margin-top: 0px;
        padding: 90px 10px;
        height: 300px;
        background-color: rgba(46, 37, 37, 0.6);
            

        @media screen and (max-width: 770px) {
            height: 700px;
            margin-top: 0px;
            padding: 50px 10px;
            
        }
        
        h2 {
            text-align: center;
            font-size: 32px;
            color: rgb(251, 248, 248);

        }

        a {
            color: #ffffff;
            text-decoration: none;
            text-shadow:
                0px 0px 0 $fspsRed,
                -1px -1px 0 $fspsRed,  
                1px -1px 0 $fspsRed,
                -1px 1px 0 $fspsRed,
                1px 1px 0 $fspsRed;

            @media screen and (max-width:770px) {
                br.md {display:inline;/*Enable br tags in screen width<=770px*/}
                
            }

            &:hover {
                text-decoration: underline;
            }
        }

        p {
            margin-top:30px;
            max-width: 950px;
            text-align: center;
            font-size: 20px;
            color: rgb(251, 248, 248);
        }
    }

    #video-area {
        padding: 40px;
        width: 100%;

        background-color: $fspsRed;

        #video-wrapper {
            margin: 0px auto;

            width: 100%;
            max-width: (960px + ($borderWidth * 2)); // Video Size + Border
            height: 100%; // 540px + borders

            border: $borderStyle;


            video {
                display: block;
                width: 100%;
                outline: none;
            }
        }

        @media screen and (max-width:415px) {
            padding:20px 0px;
            border: 0px;
        }

    }

    #mission-statement {
        margin: 80px auto;
        padding: 0px 10px;
        text-align: center;
        background: $TNWhite;

        h2 {
            color: $fspsCharcoal;
            margin: 20px;
        }

        p {
            font-size: 20px;
            color: rgb(58, 49, 49);
        }
    }

}


#service-button-content {
    background-color: rgba(255, 255, 255, 0.7);
    max-width: 100%;

    

    #service-button-header-wrapper {
        margin: 0px auto 0px;
        margin-bottom: 10px;
        max-width: 960px;
        padding: #{$logoSize - 175px} 20px 0px;
        font-size: 20px;
        text-align: center;



        h2 {
            display: inline;
            opacity: 0;
            color: $fspsCharcoal;
            text-shadow: $textShadowWhite;
            line-height: 1.35em;
        }

        h3 {
            margin-top: 50px;
            font-size: 24px;
        }

        p {
            margin-top: 20px;
        }

        @media screen and (min-width: 565px) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-flow: column nowrap;
                    flex-flow: column nowrap;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            line-height: 24px;
            padding: #{$logoSize - 75px} 20px 0px;

        }

        
        .buttonContainer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;

            -ms-flex-flow: row wrap;
            flex-flow: row wrap;

            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            .buttonlink {
                display: block;
                margin: 30px;
        
                width: 400px;
                max-width: 450px;
                height: 100px;
                
                background-color: $fspsRed;
                -webkit-box-shadow: $boxShadow0;
                box-shadow: $boxShadow0;
                border-radius: $borderRadius4;
                color: white;
                font-size: 24px;
                text-decoration: none;
                text-align: center;
                line-height: 100px;
        
                @media screen and (max-width: 420px) {
                    font-size: 24px;
                }
                @media screen and (max-width: 250px) {
                    font-size: 1em;
                }
            }
            a.buttonlink:hover {
                background-color: rgba(39, 39, 39, .9);
            }    
        }
    }


    .serviceCategory {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;

        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;

        -ms-flex-line-pack: center;
        align-content: center;

        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;

        margin: 20px auto;
        padding: 0px 20px;
        max-width: 960px;
        min-height: 170px;

        overflow: auto;

        @media screen and (max-width:415px) {
            margin:0px auto;
        }

        
        .categoryHeaderWrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;

            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;

            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            margin: 0px auto;
            padding: 60px 20px;

            width: 100%;
            height: 150px;

            opacity: 0;

            background-color: white;
            color: $fspsCharcoal;
            -webkit-box-shadow: $boxShadow1;
                    box-shadow: $boxShadow1;
            border-radius: $borderRadius2;
            border: 10px solid $fspsRed;

            h3 {
                display: block;
                font-size: 1.5em;
                padding: 5px 0px;
                border-radius: $borderRadius2;
                text-shadow: $textShadow1;
            }

            cursor: pointer;
        }

        ul.serviceList {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;

            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;

            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;

            -ms-flex-line-pack: start;
            align-content: flex-start;

            padding: 0px;

            width: 100%;
            height: 0px;

            overflow: hidden;

            img {
                display: none;
            }

            li {
                -webkit-box-flex: .5;
                    -ms-flex: .5 1 calc((100% / 3) - 10px);
                        flex: .5 1 calc((100% / 3) - 10px);
                
                
                padding: 15px;
                margin: 20px auto;
                min-width: 160px;

                background-color: $whiteTransparent2;
                border-radius: $borderRadius2;
                -webkit-box-shadow: $boxShadow1;
                        box-shadow: $boxShadow1;
                color: $fspsCharcoal;

                font-size: .80em;
                font-weight: bold;
                list-style-type: none;
                text-align: center;
                // white-space: nowrap;

                cursor: pointer;

            }

        }

        ul.serviceInfoContainer {
            margin: 0px auto;
            padding: 0px 20px 20px 20px;

            width: 100%;
            height: 100%;

            background: rgb(243, 240, 240);
            border-radius: 3px 3px 20px 20px;

            overflow: hidden;

            li {
                height: 0px;
                width: 100%;
                overflow: hidden;

                p {
                    font-size: 1.2em;
                    font-weight: bold;
                    margin-bottom: 12px;
                }

                h2 {
                    text-align: center;
                    color: $fspsRed;
                }

                .outlinkContainer {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;

                    -webkit-box-orient: horizontal;

                    -webkit-box-direction: normal;
                    -ms-flex-flow: row wrap;
                    flex-flow: row wrap;

                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    .outlink {
                        display: block;
                        margin: 30px;
                
                        width: 400px;
                        max-width: 450px;
                        height: 75px;
                        
                        background-color: whitesmoke;
                        -webkit-box-shadow: $boxShadow0;
                        box-shadow: $boxShadow0;

                        border-radius: $borderRadius2;
                        border-color: black;
                        color: $fspsCharcoal;
                        font-size: 24px;
                        text-decoration: none;
                        text-align: center;
                        line-height: 75px;

                    }
                }
            }
        }

        .dividerLine {
            display: block;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;

            height: 1px;
            margin: 10px 0px 15px;
            background-color: $fspsCharcoallow;
        }

        .scrollUp {
            display: block;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;

            height: 30px;
            margin-top: 10px;
            padding: 0px;

            border-radius: $borderRadius2;
            background-color: white;
            color: white;
            font-size: 1em;
            font-weight: bold;
            line-height: 30px;
            text-align: center;

            @media screen and (min-height: 821px) {
                display: none;
            }

        }
    }

    @import 'generators_340';
}
