#contact-banner {
    // Contact page banner acts as a background
    position: fixed;
    top: 0px;
    height: 100vh;
    z-index: -1;
}

#contact-content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    margin-top: 225px;
    padding: 0px 20px;
    width: 100%;

    @media screen and (max-width: #{$logoBP2}),
    screen and (max-height: #{$landscapeBP}) {
        margin-top: 125px;
    }

    @media screen and (max-width: #{$logoBP1}) {
        margin-top: 70px; //Height of the top bar
    }

    h1 {
        margin: 20px 0px;

        color: white;
        text-shadow: $textShadow;
        font-size: 48px;

        @media screen and (max-width: #{$logoBP2}),
        screen and (max-height: #{$landscapeBP}) {
            margin: 10px 0px;
            font-size: 32px;
        }
    }

    p {
        margin: 10px 0px;

        color: white;
        text-shadow: $textShadow;
        font-size: 30px;
        font-weight: bold;

        @media screen and (max-width: #{$logoBP2}),
        screen and (max-height: #{$landscapeBP}) {
            font-size: 20px;
        }
    }

    #contact-area {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;

        -webkit-box-flex: 50%;

            -ms-flex: 50%;

                flex: 50%;
        margin-top: 20px;

        #options-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;

            padding: 0px 10px;
            width: 50%;

            .option {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-flow: row wrap;
                        flex-flow: row wrap;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;

                margin-bottom: 40px;
                width: 100%;

                a {
                    text-decoration: none;
                    cursor: pointer;

                    img {
                        display: block;
                        margin-right: 20px;

                        width: 100%;
                        height: 100%;

                        max-width: 60px;
                        max-height: 60px;
                    }

                }

                p {
                    margin: 20px 0px;
                    width: 300px;
                    font-size: 24px;
                    font-weight: bold;
                    text-shadow: $textShadow;
                    font-weight: bold;
                    color: white;

                    @media screen and (max-width: 240px) {
                        width: 100%;
                    }
                }

                @media screen and (max-width: #{$logoBP2}),
                screen and (max-height: #{$landscapeBP}) {
                    margin-bottom: 20px;

                    a {
                        img {
                            max-width: 40px;
                            max-height: 40px;
                        }

                        p {
                            margin: 20px 0px;
                            font-size: 20px;
                            width: 250px;
                        }
                    }
                    
                }

                @media screen and (max-width: 699px) {
                    a {
                        img {
                            margin: 0px 20px;
                        }
                    }
                }

                @media screen and (max-width: 580px) {
                    a {
                        width: 100%;

                        img {
                            margin: 0px auto;
                        }

                        p {
                            width: 100%;
                            text-align: center;
                        }

                        #email-prompt {
                            margin-bottom: 0px;
                        }
                    }
                    
                }
            }
        }

        // End #options-container selector

        form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-flow: column nowrap;
                    flex-flow: column nowrap;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            padding: 0px 20px;
            width: 50%;

            .inputData {
                margin: 10px 0px;
                padding: 20px;

                width: 100%;
                max-width: 480px;
                height: 40px;

                border: solid 2px white;
                border-radius: $borderRadius;
                background-color: white;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;

                color: $fspsCharcoal;
                font-weight: bold;

                &:focus:valid,
                &.filled:valid {
                    border: solid 2px #00DC00;
                }

                &:focus:invalid,
                &.filled:invalid {
                    border: solid 2px red;
                }

            }

            /// Size of message input box
            textarea.inputData {
                height: 200px;
                resize: none;
            }

            #submit-button {
                margin: 10px 0px;
                padding: 0px 20px;

                height: 30px;
                line-height: 29px;

                border: unset;
                border-radius: $borderRadius;
                background: $gradient;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;

                color: $fspsCharcoal;
                font-weight: bold;
                font-size: 16px;

                cursor: pointer;

                &:active {
                    background: $gradientReverse;
                }

                &:disabled {
                    color: #BBB;
                }
            }
        }

        // End form selector

        @media screen and (max-width: 500px) {

            #options-container,
            form {
                width: 100%;
            }

            #options-container {
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;

                .option {
                    p {
                        text-align: center;
                    }
                }
            }
        }

    }

    // End #contact-area

    hr {
        margin: 20px 0px;
        width: 100%;

        color: white;
        -webkit-box-shadow: $boxShadow;
                box-shadow: $boxShadow;

    }

    #address-area {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;

        margin-bottom: 20px;
        width: 100%;

        p {
            -webkit-box-flex: 40%;
                -ms-flex: 40%;
                    flex: 40%;

            margin: 0px 0px 10px 0px;
        }

        iframe {
            -webkit-box-flex: 60%;
                -ms-flex: 60%;
                    flex: 60%;

            width: 100%;
            height: 480px;

            border: $borderStyle;
            -webkit-box-shadow: $boxShadow;
                    box-shadow: $boxShadow;
        }
    }

}

// End #contact-content-wrapper selector



/* -------------------------------- REVEAL SUB-MENU CONTACT FORM  ----------------------- */

#schedule-form-reveal {
    position: fixed;
    top: 0px;
    padding-top: 80px;
    display: block;
    height: 180px;
    width: 100vw;
    background: radial-gradient(rgb(221, 55, 58), $fspsRed);
    z-index: 1;

    a {
        color: white;
        
        padding: 0px 0px 0px 50px;

        &:hover {
            text-decoration: underline;
        }
    }

    .phone-icon {
        padding: 0px;
    }

    h1 {
        color: white;
        font-family: ddchardware-regular;
        font-weight: 900;
        margin-bottom: 5px;
        text-shadow: 2px 2px $fspsCharcoal;

        @media(max-width: 855px) {
            display:none;
        }

    }

    @media(max-width: 855px) {
        z-index: 1;
    }

    @media(max-width: 745px) {
        padding-top: 60px;
        height: 150px;
    }

}

.reveal-form-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    
    max-width: 100%;
    margin: auto;
    background-color: none;
}

fieldset {
    border:0; 
}


.reveal-form-container {
    width: 855px;
    /*background-color: rgba(46, 37, 37, .05);*/
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-shadow: 0 1px 1px rgba (0,0,0,.075) inset;
            box-shadow: 0 1px 1px rgba (0,0,0,.075) inset;

    input {
        color: rgb(190, 190, 190);
        background-color: rgba(46, 37, 37, .1);
        border: 1px solid $fspsCharcoallow;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 550;
        letter-spacing: 1px;
        padding: 5px;
        text-shadow: 1px 1px $fspsCharcoal;
        width: 175px;
        
        label {
            z-index: 0;
        }
    
    }

    .reveal-submit {
        color: white;
        background: radial-gradient($fspsCoolsky, rgb(0, 111, 139));
        border: 5px solid white;
        letter-spacing: 2px;
        padding: 7px;
        font-weight: 900;
        

        cursor: pointer;
        &:hover {
            background: radial-gradient(rgb(1, 201, 252), $fspsCoolsky);
        }

    }


    @media(max-width: 855px) {
        width: 100%;

        input{
            display:none;
        }
    }

    .inputData {

        

        &:focus:valid,
        &.filled:valid {
            border: solid 2px #00DC00;
        }

        &:focus:invalid,
        &.filled:invalid {
            border: solid 4px rgb(255, 0, 34);
        }
    }
}

//Clickable phone number replaces contact form under menu
.phone-link-mobile {
    display: none;

    img {
        display: block;
        margin-right: 20px;
        margin-top: 5px;

        width: 100%;
        height: 100%;

        max-width: 40px;
        max-height: 40px;

    }

    @media(max-width: 855px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: .5em 1.0em;
        font-size: 40px;
        a {
        color: white;
        text-decoration: underline;
        text-shadow: 1px 1px $fspsCharcoal;
        }
    
    }

    @media(max-width: $iPhone678) {
        padding: .75em .25em;

        a {
            font-size: 25px;

        }

    
        img {
            max-width: 23px;
            max-height: 23px;
        }
    }
}
