#installations-content-wrapper {
    // Content starts over the banner image, not after it.
    // This wraps the entire page content (introduction & the main sections)
    position: relative;
    top: 0px;

    width: 100%;
    height: 100%;

    z-index: 0;

    #transparent-content-wrapper {
        // Wrapper for the main content of the page (section buttons & actual sections)

        width: 100%;
        background-color: rgba(251, 251, 248, .45);
        border-top: 1rem solid rgba(0,0,0,0);
        border-bottom: 1rem solid rgba(0,0,0,0);
        margin-bottom: 30px;

        #inner-nav-button-container {
            // Container for the page section buttons that appears in the first content section
            // Note I did not nest this in <section> selector in case we want to move it.

            display: -webkit-box;

            display: -ms-flexbox;

            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start;

            margin: 20px auto;

            width: 100%;
            max-width: 960px;
            height: 100%;

            .sectionButton {
                // Buttons to navigate to the section on the same page, 
                // refer to button styles in _base.scss
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                border-radius: $borderRadius;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;
                color: $TNGrey;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-flow: row nowrap;
                        flex-flow: row nowrap;
                height: 160px;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                margin: 10px;
                padding: 10px;
                width: 100%;
                max-width: 300px;

                img {
                    // The thumbnail image inside the button

                    display: block;
                    width: 100%;
                    max-width: 140px;

                    height: 100%;
                    max-height: 140px;

                    border-radius: 50%;
                    border: $borderStyle;
                    -webkit-box-shadow: $circleShadow;
                            box-shadow: $circleShadow;
                    overflow: hidden;
                }

                span {
                    // The title of the section as it appears in the button

                    margin-left: 10px;
                    width: 100%;
                    max-width: 130px;
                    font-weight: bold;
                    font-size: 22px;
                    text-align: left;

                }
            }

            @media screen and (max-width: #{$logoBP2}),
            screen and (max-height: #{$landscapeBP}) {
                .sectionButton {

                    max-width: 230px;
                    height: 110px;

                    img {
                        max-width: 70px;
                        max-height: 70px;
                    }

                    span {
                        font-size: 18px;
                    }
                }
            }

            @media screen and (max-width: 270px){
                .sectionButton {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        section {
            // Styles for each main content section (navigation buttons, locations, & services)
            margin: 0px auto;
            padding: 40px 0px;

            width: 100%;
            max-width: 960px;

            h2 {
                // Section headers that appears in each section element
                padding: 0px 10px;
                margin: 0px auto 20px;
                font-size: 32px;
                text-align: center;
    
                @media screen and (max-width: #{$menuIconBP}),
                screen and (max-height: #{$landscapeBP}) {
                    font-size: 26px;
                }
    
            }
        }
        
    
    }
}

// Background video on HVAC Installations page
#backgroundVideo {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);

    height: auto;
    width: auto;
    overflow: hidden;
    z-index: -10;


    @media screen and (max-width: 765px) {
        display: none;
    }
}

.background-image {
    display: none;

    @media screen and (max-width: 765px) {
        position: fixed;
        display: block;
        height: auto;
        width: auto;
        z-index: -10;
    }
}