#gallery-content-wrapper {
    background-color: whitesmoke;
    max-width: 100%;

    #intro-section {
        position: relative;

        margin: 0px auto;
        padding: #{$logoSize + 20px} 0px 20px;

        #gallery-banner {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            z-index: 0;
        }

        h1 {
            position: relative;

            font-size: 48px;
            font-weight: bold;
            color: white;
            text-shadow: $textShadow;


            margin: 0px auto 20px;
            padding: 0px 30px;
        }

        p {
            position: relative;

            padding: 0px 20px;

            color: whitesmoke;
            text-shadow: $textShadow;

            font-weight: bold;
            font-size: 22px;
        }

        #gallery-prompt {
            position: relative;

            margin: 40px auto 0px;
            text-align: center;

            color: white;
            text-shadow: $textShadow;
        }

        @media screen and (max-width: #{$logoBP2}),
        screen and (max-height: #{$landscapeBP}) {
            padding-top: #{$logoSize / 2 + 20px};

            h1 {
                font-size: 30px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    #expanded-image-overlay {
        position: fixed;
        top: 0px;

        width: 100vw;
        height: 100vh;

        opacity: 0;
        pointer-events: none;

        z-index: 5;

        #image-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: -webkit-box;

            display: -ms-flexbox;

            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-flow: column nowrap;
                    flex-flow: column nowrap;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            margin: auto;
            padding: 0px 10px;

            width: 100%;
            max-width: 960px;
            height: auto;

            z-index: 6;


            #prompt,
            #description {
                color: white;
                text-shadow: $textShadow;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                margin: 0px auto;
            }

            img {
                margin: 20px auto;
                width: 100%;
                -o-object-fit: contain;
                   object-fit: contain;
            }

            #button-section {

                display: -webkit-box;

                display: -ms-flexbox;

                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;

                margin-top: 20px;

                button {
                    -webkit-box-sizing: content-box;
                            box-sizing: content-box;

                    margin: 0px 10px;
                    padding: 8px;
                    border-radius: $borderRadius3;

                    width: 72px;
                    height: auto;

                    font-size: 18px;
                }
            }

        }

        #background-filter {
            // Darkens the background

            position: absolute;
            top: 0px;
            background-color: rgba(0, 0, 0, .85);
            width: 100%;
            height: 100%;

            z-index: 0;
        }
    }

    hr {
        height: 10px;
        background-color: $fspsRed;
        width: 100%;
        border: none;
    }

    #thumbnail-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;

        margin: 0px auto;
        padding: 20px 6px;


        img {
            -webkit-box-sizing: content-box;
                    box-sizing: content-box;
            margin: 6px;

            width: 100%;
            height: 100%;
            max-width: 225px;
            max-height: 225px;

            cursor: pointer;

            &:hover,
            &.active {
                margin: 2px;
                border: 4px solid $fspsRed;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;
            }
        }

    }
}