#background-banner {
    // Crew page banner acts as a background
    position: fixed;
    top: 0px;
    height: calc(100vh - 0px);
    z-index: -1;
}

#background-content-wrapper {

    margin: 0px auto;
    width: 100%;
    max-width: 960px;
    height: 100%;

    padding: #{$logoSize - 150px} 20px 0px;

    #inner-page-links {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly;

        button {
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            margin: 30px;
            padding: 20px;
            font-size: 24px;
            height: 69px;
            width: 100%;
            max-width: 240px;
            border: 3px solid rgba(226, 18, 20, 0.8);
            border-radius: 15px;
            background: $TNWhite;
            background-image: radial-gradient(rgba(184, 171, 171, 0.2),rgba(251, 251, 248, 0.5));
            font-family: 'Roboto Condensed', sans-serif;

            &:hover {
                background-color: $fspsCharcoallow;
                background-image: none;
                color: $fspsRed;
                border: 5px solid $fspsRed;
                text-shadow: 1px 1px black;
            }


        }
    }


    @import '../components/bottom/overlay';

    section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column nowrap;
                flex-flow: column nowrap;

        margin: 20px 0px;
        padding: 10px;

        width: 100%;
        height: 100%;

        -webkit-box-shadow: $boxShadow;

                box-shadow: $boxShadow;
        border: 3px solid rgba(0, 0, 0, 0.2);
        border-radius: $borderRadius;
        background: $TNWhite;
        background-image: radial-gradient(rgba(184, 171, 171, 0.1),rgba(251, 251, 248, 0.9));


        a {

            &:hover {
                color: $fspsRed;
            }
        }

        h1 {
            font-size: 48px;
            margin: 20px 10px;

        }

        h2 {
            font-size: 36px;
            margin: 20px 10px 0px;
        }

        h3 {
            font-size: 24px;
            margin: 0px 10px 0px;
        }

        hr {
            height: 2px;
            background-color: $fspsRed;
            border: none;
            margin: 0px 10px;
        }

        P {
            // Generic paragraph styles for these sections.

            // By default, markdown-it will wrap all elements within a
            // <p> tag, you can change this but I have chosen to just work around it.
            // Nested in this p tag will be additional styles for elements
            // that have been wrapped by a p tag due to the markdown-it filter
            // applicable to the services page.

            margin: 20px 17px;

            height: 100%;

            font-size: 22px;
            font-weight: bold;
            text-align:justify;
        }

        img {
            display: block;
            margin: 10px auto 0px;
            width: 100%;
            border: none;

        }

        img.center {
            margin-left: auto;
            margin-right: auto;

        }

 

        .specialtyButton {
            display: block;

            margin: 10px 0px;
            padding: 10px;

            height: 100px;
            line-height: 80px;
            
            border: 3px solid rgba(226, 18, 20, 0.2);
            border-radius: $borderRadius;
            -webkit-box-shadow: $boxShadow;
                    box-shadow: $boxShadow;
            background: $gradient;

            text-align: center;

            cursor: pointer;

            &:hover {
                background: $fspsRed;
                color: white;
            }

            &:active {
                background: $fspsRed;
            }
        }

        .specialtyBlurb {
            img {
                margin: 10px 0px;
            }
            margin: 0px 10px;
            font-size: 18px;

            height: 0px;
            overflow: hidden;
        }

        ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
            -webkit-box-pack: space-evenly;
                -ms-flex-pack: space-evenly;
                    justify-content: space-evenly;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start;
            height: 0px;
            overflow: hidden;

            li {
                // display: 
                text-align: center;
                margin: 10px;
                padding: 10px;

                list-style: none;

                font-weight: bold;


                width: auto;
                min-width: 155px;
                height: auto;

                background-color: $TNWhite;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;
                border-radius: $borderRadius;
            }
        }

    } // End section


    @media screen and (max-width: #{$iPhone678}),
    screen and (max-height: #{$landscapeBP}) {
        padding-top: #{$logoMobile / 2};

        #inner-page-links {
            button {
                margin: 0px;
                padding: 5px;
                font-size: 18px;
                max-width: 100px;
            }
            
        }

        section {
            h1 {
                font-size: 32px;
                text-align: left;
            }
    
            h2 {
                font-size: 22px;
            }
    
            h3 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: #{$logoBP1}) {
        padding-top: 90px;

        section {
            h1 {
                font-size: 24px;
                text-align: left;
            }
    
            h2 {
                font-size: 20px;
            }
    
            h3 {
                font-size: 16px;
            }

    

            p {
                font-size: 14px;
            }
        }
    }
} // End crew-content-wrapper