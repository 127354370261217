#footer-wrapper {
    width: 100vw;
    height: $footerHeight;

    background: $gradientBlack;

    #footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        padding: 0px 20px;
        margin: 0px auto;
        max-width: 960px;
        height: 100%;

        small {
            color: white;
            text-align: center;

            a {
                color: $fspsRed;
            }

            img{
                margin: 0px 10px;
            }
        }
    }
}

.footer-full-width-element-container {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    height: 33%;
    width: 100%;
}