/* ---------------- _globals.scss ---------------- */

// Five Star Power Systems colors
$fspsRed: #e21214;
$fspsRedlow: rgba(226, 18, 20, 0.4);
$fspsRedlower: #e2121433;
$fspsCharcoal: #2e2525;
$fspsCharcoallow: rgba(46, 37, 37, 0.4);
$fspsCharcoallower: rgba(46, 37, 37, 0.2);
$fspsCoolwater: #006f8b;
$fspsHeat: rgb(254, 123, 0);
$fspsHeatlow:rgb(254, 123, 0.2);
$fspsCoolsky: #00445f;

$TNWhite: rgb(251, 251, 248);
$TNGrey: #333;


.fsps-linear-grey-Charcoal-grey {
    background: -webkit-gradient(linear, left top, left bottom, from(grey), color-stop($fspsCharcoal), to(grey));
    background: linear-gradient(to bottom, grey, $fspsCharcoal, grey);
}


// Top Overlay 
$topBarHeight: 175px;

$logoSize: 322px;
$logoMobile: 125px;
$logoPadding: 20px;


// widths
$logoBP1: 300px;
$menuIconBP: 570px;
$logoBP2: 745px;
$iPadPro: 1024px;
$iPad: 768px;
$iPhone678: 414px;
$iPhone5: 320px;
$GalaxyS5: 360px;

// For short view heights, pairs with logoBP2
// e.g. when you rotate a mobile device
$landscapeBP: 480px;


// Footer// Footer 
$footerHeight: 150px;


// General 
$borderWidth: 4px;
$borderStyle: $borderWidth solid $fspsRed;
$borderStyle2: $borderWidth solid $fspsCharcoallow;

$borderRadius: 30px;
$borderRadius2: 10px;
$borderRadius3: 15px;
$borderRadius4: 30px;

$gradient: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(46, 37, 37, 0.05));
$gradient2: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(46, 37, 37, 0.1));
$gradientcool: linear-gradient(to bottom, rgba(251, 251, 248, 1.0), rgba(0, 111, 139, .1));
$gradientheat: linear-gradient(to bottom, rgba(254, 123, 0, .05), rgba(251, 251, 248, 1.0), rgba(254, 123, 0, .05));
$gradientReverse: linear-gradient(to top, $TNWhite, #D0D0D0);
$gradientBlack: linear-gradient(to top, black, #1F1F1F);

$boxShadow: 1px 2px 4px 0px rgba(0, 0, 0, .2),
0px 2px 4px 0px rgba(0, 0, 0, .2),
-1px 2px 4px 0px rgba(0, 0, 0, .2);
$boxShadow0: 0px 2px 5px black;
$boxShadow1: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
$boxShadow2: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
$boxShadowNavButton: 0px 1px 2px #E21214;
$circleShadow: 0px -1px 4px 0px rgba(0, 0, 0, .2),
1px 0px 4px 0px rgba(0, 0, 0, .2),
0px 1px 4px 0px rgba(0, 0, 0, .2),
-1px 0px 4px 0px rgba(0, 0, 0, .2);
$textShadow: -1px 2px 4px rgba(0, 0, 0, .3),
0px 1px 4px rgba(0, 0, 0, .3),
1px 2px 4px rgba(0, 0, 0, .3);
$textShadow1: 1px 1px 2px rgba(0, 0, 0, 0.8);
$textShadowWhite: 1px 2px 2px white;
$textShadowRed: 2px 2px 2px rgba(226, 18, 20, 1.0);

$whiteTransparent1: rgba(255, 255, 255, 0.9);
$whiteTransparent2: rgba(255, 255, 255, 0.6);



// Device height - navbar - aprox. header - footer
$contentHeight: calc(100vh - 40px - 100px - 240px);

// ---------- Base Styles ----------
// Reset

/* roboto-condensed-regular - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/roboto-condensed-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
         url('./fonts/roboto-condensed-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/roboto-condensed-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/roboto-condensed-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/roboto-condensed-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/roboto-condensed-v18-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
/* roboto-condensed-700 - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/roboto-condensed-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
            url('./fonts/roboto-condensed-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('./fonts/roboto-condensed-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('./fonts/roboto-condensed-v18-latin-700.woff') format('woff'), /* Modern Browsers */
            url('./fonts/roboto-condensed-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('./fonts/roboto-condensed-v18-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}


* {
    margin: 0px;
    padding: 0px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    &:focus {
        outline: none;
    }


    // Remove the blue overlay when tapping on links in mobile browsers
    // This is a non-standard property but it seems to work for most commonly used browsers.
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; 
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100vw;
    height: 100%;

    font-family: 'Roboto Condensed', sans-serif;

    background-color: $TNWhite;
    color: $TNGrey;
}

.fixedCenter {
    margin: 0px auto;
    width: 100%;
    max-width: 960px;
}


button {
    // Generic styles for buttons
    display: block;
    border: none;
    text-decoration: none;
    font-weight: bold;
    text-align: center;

    cursor: pointer;

    &:active {
        background: $gradientReverse;
    }
}



a {
    // Generic styles for links
    text-decoration: none;
    color: $fspsCharcoal;
    font-weight: bold;
}


#body-content {
    padding-top:175px;
    height: auto;
    min-height: calc(100vh - #{$footerHeight});
    
    @media screen and (max-width: 420px) {
        padding-top: 87px;
    }
}


h1 {
    text-align: center;
}

// Banner options


/* Main Area Wrapper */

.contentWrapper {
    width: 100vw;
    min-height: $contentHeight;
}


.bannerImageWrapper {
    position: relative; // position is fixed (so it replicates a background) on the contact page
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        // Image will retain its dimensions and be displaced into the center when the
        // screen starts to get smaller, the outer div acts as a box that will
        // crop the image so it doesnt cover the whole screen.
        position: absolute;
        margin-left: calc(-960px + (100vw / 2));
    

        display: block;
        overflow: hidden;
        width: 1920px;
        height: 900px;

        @media screen and (max-width: $iPadPro) {
            min-width: 220%;
            min-height: 100%;
        }
    }

    .nocontactform {
        margin-top: 70px;
    }
    
}

.filter-black {
    // Applies a filter over the background image to make it darker (not used on home/index page)
    position: absolute;

    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
}

.filter-black-progression {
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.25)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25));
}

.filter-black-progression-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.4)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.4));
}

.filter-black-progression-3 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.55)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.55));
}

.filter-black-progression-4 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(rgba(0, 0, 0, 0.65)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.65));
}

#quarter-content-boxes-container {
    background: $TNWhite;
    border-top: 5px solid red;
    width: 100vw;
    
    padding-top: 225px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    height: 790px;


    .quarter-content-boxes {
        position: relative;
        display: inline-block;
        height: auto;
        min-height: 30vh;
        width: 24.75vw;
        min-width: 320px;
        border: 5px solid red;
        background: radial-gradient($fspsCoolsky, rgb(0, 111, 139));
        text-align: center;
        overflow: hidden !important;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 30px;
        cursor: pointer;

        &:hover {
            background: $fspsRed;
            border: 8px solid $fspsCoolwater;
        }

        h1, h2, h3, p {
            color: white;
            padding: 5px;
        }

        h2 {
            font-size: 2.1em;
        }

        p {
            font-size: 1.5em;
        }

        @media screen and (max-width: $iPad) {
            width: 49.75vw;
        }

        @media screen and (max-width: $iPhone678) {
            width: 100%;
        }

    }

    @media screen and (max-width: $iPhone678) {
        padding-top: 100px;
        height: 1100px;
        margin-top: -300px;
    }

}

.margin-top-installations {
    margin-top: 30px;
}

.modal-header-sticky-overlay {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    margin-bottom: 50px;
    padding: 0px 10px;
    width: 100vw;
    min-height: 100px;

    overflow: hidden;

    background-color: rgba(#006f8b, 0.4);
    border: 5px solid red;

    h1 {
        color: $TNWhite;
        text-shadow: 2px 2px 5px black;

        font-size: 86px;
    }

}



/* The Quarter Content Modal (background) */

.quarter-content-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */

}

/* Quarter Modal Content box */
.quarter-modal-content-box {
    background: radial-gradient($fspsCoolsky, rgb(0, 111, 139));
    border: 3px solid $fspsRed;
    
    
    margin: auto;
    overflow: scroll;
    padding-top: 98px;
    width: 50vw;
    height: 100%;
    z-index: 11;

    

    h2 {
        background-color: $fspsCoolsky;
        line-height: 100px;
        color: white;
        
    }

    h3 {
        padding-left: 10px;
        color: rgb(240, 234, 234);
        -webkit-text-decoration: underline $fspsRed;
                text-decoration: underline $fspsRed;
        letter-spacing: 1px;
    }

    

    p {
        padding: 14px; 
        text-align: justify;
        color: white;
    }

    @media screen and (max-width: $iPad) {
        width: 90vw;
        border-radius: 100px 100px 0px 0px;

        h2 {
            line-height: 50px;
            background: none;
        }
    }
}


/* The Quarter Content Modal Close Button */
.close-quarter-content-modal {
    color: $fspsRedlow;
    text-shadow: #000 0px 2px;
    float: right;
    font-size: 58px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    border: 2px dotted #dacccc;
}
  
  .close-quarter-content-modal:hover,
  .close-quarter-content-modal:focus {
    color: $fspsRed;
    text-decoration: none;
    cursor: pointer;
}


/* -------------------------------- Full Screen Height, Half Screen Width Divs --------------------------------------*/

.fifty-fifty-ImageWrapper {
    position: relative; 
    display: inline-block;
    height: calc(100vh - 175px);
    width: 50vw;

    overflow: hidden;
    cursor: pointer;
    
    #fifty-fifty-left-img {
        background-image: url("/images/index/five-star-power-systems-HVAC-Greyscale-background.jpg");
        height: calc(100vh - 175px);
         
        	/*Transition*/
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

        img {
            // Image will retain its dimensions and be displaced into the center when the
            // screen starts to get smaller, the outer div acts as a box that will
            // crop the image so it doesnt cover the whole screen.
            
            display: block;
            margin-left: auto;
            margin-right:auto;
            width: auto;
            margin-top: 0px;
            padding-top:150px;

            @media screen and (max-width: $iPadPro) {
                padding-top: 300px; 
            }

            @media screen and (max-width: $iPhone678) {
                padding-top: 100px; 
            }

        }

        //------ set replacement image to display none so it is not seen on desktop
        img.image-mobile {
            display:none;
        }

        //------ change the images at this size so desktop is hidden and mobile size is shown
        @media screen and (max-width: $iPhone678) {
            img.image-desktop {
                display: none;
            }
            img.image-mobile {
                display: block;
            }
        }

        @media screen and (max-width: $iPhone678) {
            background-image: url("/images/index/five-star-power-systems-HVAC-Greyscale-background-mobile.jpg");
            height:calc(100vh - 87.5px);
        }
    }

    @media screen and (max-width: $iPhone678) {
        height:calc(100vh - 87.5px);
    }



    #fifty-fifty-right-img {
        background-image: url("/images/index/five-star-power-systems-Generator-Greyscale-background.jpg");
        height: calc(100vh - 175px);

        /*Transition*/
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

        img {
            // Image will retain its dimensions and be displaced into the center when the
            // screen starts to get smaller, the outer div acts as a box that will
            // crop the image so it doesnt cover the whole screen.
            display: block;
            margin-left: auto;
            margin-right:auto; 
            width: auto;
            padding-top:150px;

            @media screen and (max-width: $iPadPro) {
                padding-top: 300px; 
            }

            @media screen and (max-width: $iPhone678) {
                padding-top: 115px; 
            }
            
        }

        //------ set replacement image to display none so it is not seen on desktop
        img.image-mobile {
            display:none;
        }

        //------ change the images at this size so desktop is hidden and mobile size is shown
        @media screen and (max-width: $iPhone678) {
            img.image-desktop {
                display: none;
            }
            img.image-mobile {
                display: block;
            }
}

        @media screen and (max-width: $iPhone678) {
            background-image: url("/images/index/five-star-power-systems-Generator-Greyscale-background-mobile.jpg");
            height:calc(100vh - 87.5px);
        }
    }

    @media screen and (max-width: $iPhone678) {
        height:calc(100vh - 87.5px);
    }

}


.fifty-fifty-title-overlay {
    position: absolute;
    bottom: 42vh;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    padding: 0px 10px;
    height: 75px; 
    width: 100%;
    min-height: 10%;

    overflow: hidden;

    @media screen and (max-width: $iPhone678) {
        bottom: 35vh;
    }


    h1 {
        font-size: 2.5em;
        color: white;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: .5em;
        padding: 0px 5px; 

        @media screen and (max-width: $iPhone678) {
            font-size: 2.0em;
        }
    }
}


#fifty-fifty-left-img:hover {
    opacity:1;

    background-image:url("/images/index/five-star-power-systems-HVAC-Color-background.jpg");
    /*background-size:cover;
    background-position:center;
    */
    background-size:100%;
    color:#ED2E14;
    background-color:transparent;
    /*Glow*/
    -webkit-box-shadow: 0px 0px 20px rgba(255,255,255,0.8);
    box-shadow: 0px 0px 20px rgba(255,255,255,0.8);
    
    h1 {
        color: white;
        font-size: 4.5em;
        background-color: rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: $iPhone678) {
        h1 {
            font-size: 3.0em;
        }
    }

    @media screen and (max-width: $iPhone678) {
        background-image:url("/images/index/five-star-power-systems-HVAC-Color-background-mobile.jpg")
    }
}

#fifty-fifty-right-img:hover {
    opacity:1;

    background-image:url("/images/index/five-star-power-systems-Generator-Color-background.jpg");
    /*background-size:cover;
    background-position:center;
    */
    background-size:100%;
    background-color:transparent;
    /*Glow*/
    -webkit-box-shadow: 0px 0px 20px rgba(255,255,255,0.8);
    box-shadow: 0px 0px 20px rgba(255,255,255,0.8);

    
    h1 {
        color: white;
        font-size: 4.5em;
        background-color: rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: $iPhone678) {
        h1 {
            font-size: 3.0em;
        }
    }

    @media screen and (max-width: $iPhone678) {
        background-image:url("/images/index/five-star-power-systems-Generator-Color-background-mobile.jpg")
    }
}

a.fill-div {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    background: transparent;
}

/* ------------------------------------ CLIP-PATHS ---------------------------------- */

.FivepointStar {
    margin-top: 175px;
    margin-left: auto;
    margin-right: auto;
    -webkit-clip-path: polygon(50% 2%, 61% 35%, 96% 35%, 68% 57%, 79% 89%, 50% 70%, 21% 89%, 32% 57%, 4% 35%, 39% 35%);
            clip-path: polygon(50% 2%, 61% 35%, 96% 35%, 68% 57%, 79% 89%, 50% 70%, 21% 89%, 32% 57%, 4% 35%, 39% 35%);
    max-width: 1000px;
}

.section-overlay {
    position: fixed; 
    width: 100vw;
    height: calc(100vh - 150px);
    background: $fspsRedlower;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -10;
}
