
#services-banner {
    // This banner image that acts as a background to the paragraph section when you load the page
    // Refer to .bannerImage in _base.scss for additional styles
    position: fixed;

    // Set to 120vh because on mobile browsers the screen size changes when you start to scroll
    // and the address bar disappears. This makes no difference on a desktop screen.
    height: 120vh;
    z-index: -3;

    @media (max-width: 745px) {
        top: 150px;
    }
}

#background-video-header-banner-full {
    position: relative;
    height: 100vh;
}

#background-video-title-banner {
    position: relative;
    height: auto;
}



/*---------- STICKY BANNER SECTION ----------*/

#sticky-banner-small-menu-stop {
    // This banner image acts as a background 
    // Refer to .bannerImage in _base.scss for additional styles
    position: -webkit-sticky;
    position: sticky;
    top: 175px;

    // Set to 120vh because on mobile browsers the screen size changes when you start to scroll
    // and the address bar disappears. This makes no difference on a desktop screen.
    height: 900px;

    @media screen and (max-width: $iPhone678) {
        Top: 150px;
    }


    
}


#full-page-sticky-content {
    
    display: -webkit-box;
    
    display: -ms-flexbox;
    
    display: flex;
    margin: auto;
    max-width: 960px;
    height: 100%;
    min-height: 50vh;
    width: 100%;
    border-radius: 20px;
    
    h1, h2, h3, p {
        
        margin: auto /* Important */;
        text-align: center;
        padding: 30px;
    }

    h1, p {
        color: white;
        text-shadow: 1px 4px black;
    }

    h1 {
        font-size: 4em;
    }

    h2 {
        color: $fspsRed;
        text-shadow: 1px 4px black;
        font-size: 3.25em;
    }

    h3 {
        color: $fspsCharcoal;
        font-size: 2.75em;
    }

    p {
        font-size: 1.50em;
        border-width: 25px 20px 25px 20px;
        border-style: solid;
        border-color: white;

    }


}

.sticky-content-background {
    background: rgba(255, 255, 255, .8);
    margin: auto;
    color: black;
}




#services-wrapper-space {
    margin: 0px auto;
    width: 100%;
    max-width: 960px;
    height: 0px;

}


#services-content-wrapper {
    // Content starts over the banner image, not after it.
    // This wraps the entire page content (introduction & the main sections)
    position: relative;
    top: 0px;

    width: 100%;
    height: 100%;

    z-index: 0;

    #introduction {
        // This is the initial block of elements that appears when the page loads
        // Header & paragraph is included via markdown-it filter 

        margin: 0px auto;
        padding: #{$logoSize - 50px} 20px 10px;
        width: 100%;
        max-width: 960px;
        height: 100%;
        min-height: calc(100vh - 10px);

        color: white;
        text-shadow: $textShadow;


        h1 {
            // 
            font-size: 48px;
            margin-bottom: 40px;
        }

        p {
            // Introduction paragraph
            font-size: 22px;
            font-weight: bold;
            margin: 20px 0px;
        }

        .explore-button {
            // Explore button, refer to button styles in _base.scss     
            background: white;
            background-image:$gradientheat;
            border-radius: $borderRadius;
            -webkit-box-shadow: $boxShadow;
                    box-shadow: $boxShadow;
            color: $fspsCharcoal;
            font-size: 30px;
            
            margin: 60px auto 40px;
            max-width: 160px;
            padding: 10px 0px;

            width: 100%;

            &:hover {
                color: $fspsRed;
            }           
        }

        .explore-button-heat {
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(251, 251, 248, 1.0)), to(rgb(253, 219, 188)));
            background: linear-gradient(to bottom, rgba(251, 251, 248, 1.0), rgb(253, 219, 188));
            
            &:hover {
                background: -webkit-gradient(linear, left top, left bottom, from(rgb(253, 219, 188)), to(rgba(251, 251, 248, 1.0)));
                background: linear-gradient(to bottom, rgb(253, 219, 188), rgba(251, 251, 248, 1.0));
                
            }
        }

        .explore-button-cool {
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(251, 251, 248, 1.0)), to(rgb(149, 201, 214)));
            background: linear-gradient(to bottom, rgba(251, 251, 248, 1.0), rgb(149, 201, 214));
            
            &:hover {
                background: -webkit-gradient(linear, left top, left bottom, from(rgb(149, 201, 214)), to(rgba(251, 251, 248, 1.0)));
                background: linear-gradient(to bottom, rgb(149, 201, 214), rgba(251, 251, 248, 1.0));
            }
        }

        .explore-button-water-heat {
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 1.0)), color-stop(rgb(147, 195, 207)), to(rgba(253, 219, 188, 1.0)));
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1.0), rgb(147, 195, 207), rgba(253, 219, 188, 1.0));
        
            &:hover {
                background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 219, 188, 1.0)), color-stop(rgb(147, 195, 207)), to(rgba(255, 255, 255, 1.0)));
                background: linear-gradient(to bottom, rgba(253, 219, 188, 1.0), rgb(147, 195, 207), rgba(255, 255, 255, 1.0));
            }
        }

        @media screen and (max-width: $iPadPro) {
            padding: #{$logoSize - 90px} 10px 10px 35px;
        }

        @media screen and (max-width: $iPad) {
            padding: #{$logoSize - 90px} 15px 10px 45px;
        }

        @media screen and (max-width: #{$logoBP2}),
        screen and (max-height: #{$landscapeBP}) {
            // Reduce some sizes on smaller screens
            padding-top: #{$logoSize / 2.5 + 0px};
            min-height: calc(100vh - 200px);

            h1 {
                font-size: 32px;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
            }

            .explore-button {
                margin: 20px auto 20px;

                max-width: 110px;

                font-size: 20px;
            }
        }

        @media screen and (max-width: #{$logoBP1}) {
            // Logo does not overflow menu bar at this point.
            // Only set padding to the menubar height + 20px
            padding-top: 90px;
        }

    } // End #introduction

    // Import styles for the overlay that appears at the bottom of the page
    @import '../components/bottom/overlay';

    #main-content-wrapper {
        // Wrapper for the main content of the page (section buttons & actual sections)

        width: 100%;
        background-color: $TNWhite;

        .seperator {
            // The thick orange seperators in between each section
            width: 100%;
            height: 20px;
            border:unset;
        }   
            .seperator-five-star-red {
                background-color: $fspsRed;
            }

            .seperator-thick-sky-blue {
                // The thick blue seperators in between each section on the air conditioning page
                background: radial-gradient(rgb(0, 68, 95), rgb(0, 111, 139));
            }

            .seperator-thick-water-warmth {
                //The thick watery blue seperators in between each section of the water heat
                background: radial-gradient(rgb(0, 151, 190), rgb(254, 123, 0));
            }

        #section-button-container {
            // Container for the page section buttons that appears in the first content section
            // Note I did not nest this in <section> selector in case we want to move it.

            display: -webkit-box;

            display: -ms-flexbox;

            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start;

            margin: 20px auto;

            width: 100%;
            max-width: 960px;
            height: 100%;

            .sectionButton {
                // Buttons to navigate to the section on the same page, 
                // refer to button styles in _base.scss
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                border-radius: $borderRadius;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;
                color: $TNGrey;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-flow: row nowrap;
                        flex-flow: row nowrap;
                height: 160px;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                margin: 10px;
                padding: 10px;
                width: 100%;

                img {
                    // The thumbnail image inside the button

                    display: block;
                    width: 100%;
                    max-width: 140px;

                    height: 100%;
                    max-height: 140px;

                    border-radius: 50%;
                    border: $borderStyle;
                    -webkit-box-shadow: $circleShadow;
                            box-shadow: $circleShadow;
                    overflow: hidden;
                }

                span {
                    // The title of the section as it appears in the button

                    margin-left: 10px;
                    width: 100%;
                    max-width: 130px;
                    font-weight: bold;
                    font-size: 22px;
                    text-align: left;

                }

            }

            .section-button-six {
                max-width: 300px;
                background: $gradientheat;

                &:hover {
                    background: -webkit-gradient(linear, left top, left bottom, from(rgba(254, 123, 0, .7)), color-stop(white), to(rgba(254, 123, 0, .7)));
                    background: linear-gradient(to bottom, rgba(254, 123, 0, .7), white, rgba(254, 123, 0, .7));
                    color: $fspsRed;
                }
            }

            .section-button-four {
                max-width: 450px;
                background: $gradientcool;

                &:hover {
                    background-color: $fspsCoolwater;
                    color: $fspsCoolsky;
                }
            }

            .section-button-water-heat {
                max-width: 450px;
                background: $gradientcool;

                &:hover {
                    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 1.0)), color-stop(rgba(0, 111, 139, .1)), to(rgba(254, 123, 0, .4)));
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 1.0), rgba(0, 111, 139, .1), rgba(254, 123, 0, .4));
                    color: $fspsCoolwater;
                }
            }


            @media screen and (max-width: #{$logoBP2}),
            screen and (max-height: #{$landscapeBP}) {
                .sectionButton {

                    max-width: 230px;
                    height: 110px;

                    img {
                        max-width: 70px;
                        max-height: 70px;
                    }

                    span {
                        font-size: 18px;
                    }
                }
            }

            @media screen and (max-width: 270px){
                .sectionButton {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        section {
            // Styles for each main content section (navigation buttons, locations, & services)
            margin: 0px auto;
            padding: 40px 0px;

            width: 100%;
            max-width: 960px;

            a {
                &:hover {
                    color: $fspsRed;
                    text-decoration: underline;
                }
            }

            h2 {
                // Section headers that appears in each section element
                padding: 0px 10px;
                margin: 0px auto 20px;
                font-size: 32px;
                text-align: center;

    
                @media screen and (max-width: #{$menuIconBP}),
                screen and (max-height: #{$landscapeBP}) {
                    font-size: 26px;
                }
    
            }

            h3 {
                font-size: 20px;
            }

            h4 {
                // Any subheaders or prompts
                margin-bottom: 20px;
                padding: 0px 20px;

                text-align: center;
                font-size: 20px;
                color: #737373;
            }

            P {
                // Generic paragraph styles for these sections.

                // By default, markdown-it will wrap all elements within a
                // <p> tag, you can change this but I have chosen to just work around it.
                // Nested in this p tag will be additional styles for elements
                // that have been wrapped by a p tag due to the markdown-it filter
                // applicable to the services page.

                margin: 20px 0px;
                font-size: 20px;

                img {
                    // Full-sized images that appear in each section
                    display: block;

                    margin: 20px 0px 0px;
                    width: 100%;
                    max-width: 960px;
                    height: 100%;
                    max-height: 480px;
                }
                
                // CSS selector to match ID given to small content images in the markdown
                img[src*="small-contentimage"] {
                    width: 250px;
                    margin-left: auto;
                    margin-right: auto;
                }

            }

            ul {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-flow: column nowrap;
                        flex-flow: column nowrap;

                padding: 15px;

                -webkit-box-shadow: $boxShadow;

                        box-shadow: $boxShadow;
                border-radius: $borderRadius;

                li {
                    list-style-type: none;
                    // font-weight: bold;
                    margin: 5px 0px;
                    font-size: 18px;
                }
            }
            
            .portfolioLink {
                // Styling for links that go directly below the section image.
                display: block;
                width: 100%;
                font-size: 14px;
                text-align: center;
            }

            // Other links are styled via the global <a> tag styling. See _base.scss

            .italicized {
                font-style: italic;
            }

            


            @media screen and (max-width: $iPadPro) {
                padding: 20px 5px 20px 35px;

                h2, h4 {
                    padding: 0px;
                }
                
            }



            @media screen and (max-width: #{$menuIconBP}),
            screen and (max-height: #{$landscapeBP}) {
                h2 {
                    margin: 10px auto;

                    text-align: left;
                    font-size: 24px;
                }

                h3 {
                    font-size: 16px;
                }

                h4 {
                    margin: 10px auto;
                    font-size: 16px;
                }

                p {
                    font-size: 18px;
                }
            }
        } // end .mainContent

        #county-select {
            // The service locations county option container
            position: relative;
        
            display: -webkit-box;
        
            display: -ms-flexbox;
        
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-flow: column nowrap;
                    flex-flow: column nowrap;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
        
            width: 100%;
            height: auto;
            min-height: 480px;
        
            background-color: black;
            border: 5px solid $TNGrey;
            border-radius: $borderRadius;
            -webkit-box-shadow: $boxShadow;
                    box-shadow: $boxShadow;
        
            overflow: hidden;
        
            img {
                position: absolute;
                top: 0px;
        
                width: 100%;
                height: 100%;
        
                display: block;
                -o-object-fit: cover;
                   object-fit: cover;
                
        
                z-index: 0;
            }
        
            .filter-black {
                position: absolute;
                top: 0px;
        
                width: 100%;
                height: 100%;
        
                background-color: rgba(0, 0, 0, .65);
        
                
            }
        
            button {
                // The county selection buttons
                position: relative;
        
                padding: 20px 30px;
        
                width: 100%;
        
                -webkit-box-flex: 1;
        
                    -ms-flex: 1 1 auto;
        
                        flex: 1 1 auto;
        
                background: none;
                border: none;
                border-radius: unset;
                -webkit-box-shadow: none;
                        box-shadow: none;
        
                color: white;
                font-weight: bold;
                font-size: 30px;
                text-align: left;
                text-shadow: $textShadow;
        
                cursor: pointer;
        
                &:hover {
                    background-color: rgba(0, 0, 0, .8);
                }
            }
        
            hr {
                position: relative;
                margin: 0px;
                padding: 0px;
                width: 100%;
                height: 1px;
                border: none;
                background-color: white;
            }
        
            ul {
                // The list of cities that appear
                position: absolute;
                bottom: 0px;
        
                display: -webkit-box;
        
                display: -ms-flexbox;
        
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-flow: row wrap;
                        flex-flow: row wrap;
                -webkit-box-pack: end;
                    -ms-flex-pack: end;
                        justify-content: flex-end;
                -ms-flex-line-pack: end;
                    align-content: flex-end;
        
                padding: 20px;
        
                width: 100%;
                height: calc(100% - 90px);
        
                opacity: 0;
        
                pointer-events: none;
        
                z-index: 1;
        
                li {
                    margin: 10px;
        
                    font-size: 20px;
                    font-weight: bold;
                    color: white;
                    text-shadow: $textShadow;
                    list-style: none;
                }
        
            }
        
            @media screen and (max-width: #{$menuIconBP}),
            screen and (max-height: #{$landscapeBP}) {
        
                button {
                    font-size: 24px;
                }
        
                ul {
                    li {
                        margin: 5px;
                        font-size: 16px;
                    }
                }
            }
        
            @media screen and (max-width: 430px) {
                button {
                    padding: 10px;
                    font-size: 20px;
                }
        
                ul {
                    li {
                        font-size: 12px;
                    }
                }
            }
        }

    }

    
}

