@charset "UTF-8";
/* ---------------- _globals.scss ---------------- */
.fsps-linear-grey-Charcoal-grey {
  background: -webkit-gradient(linear, left top, left bottom, from(grey), color-stop(#2e2525), to(grey));
  background: linear-gradient(to bottom, grey, #2e2525, grey); }

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/roboto-condensed-v18-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url("./fonts/roboto-condensed-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/roboto-condensed-v18-latin-regular.woff2") format("woff2"), url("./fonts/roboto-condensed-v18-latin-regular.woff") format("woff"), url("./fonts/roboto-condensed-v18-latin-regular.ttf") format("truetype"), url("./fonts/roboto-condensed-v18-latin-regular.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */ }

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/roboto-condensed-v18-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"), url("./fonts/roboto-condensed-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("./fonts/roboto-condensed-v18-latin-700.woff2") format("woff2"), url("./fonts/roboto-condensed-v18-latin-700.woff") format("woff"), url("./fonts/roboto-condensed-v18-latin-700.ttf") format("truetype"), url("./fonts/roboto-condensed-v18-latin-700.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */ }

* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }
  *:focus {
    outline: none; }

body {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
  height: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #fbfbf8;
  color: #333; }

.fixedCenter {
  margin: 0px auto;
  width: 100%;
  max-width: 960px; }

button {
  display: block;
  border: none;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
  button:active {
    background: linear-gradient(to top, #fbfbf8, #D0D0D0); }

a {
  text-decoration: none;
  color: #2e2525;
  font-weight: bold; }

#body-content {
  padding-top: 175px;
  height: auto;
  min-height: calc(100vh - 150px); }
  @media screen and (max-width: 420px) {
    #body-content {
      padding-top: 87px; } }

h1 {
  text-align: center; }

/* Main Area Wrapper */
.contentWrapper {
  width: 100vw;
  min-height: calc(100vh - 40px - 100px - 240px); }

.bannerImageWrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .bannerImageWrapper img {
    position: absolute;
    margin-left: calc(-960px + (100vw / 2));
    display: block;
    overflow: hidden;
    width: 1920px;
    height: 900px; }
    @media screen and (max-width: 1024px) {
      .bannerImageWrapper img {
        min-width: 220%;
        min-height: 100%; } }
  .bannerImageWrapper .nocontactform {
    margin-top: 70px; }

.filter-black {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)); }

.filter-black-progression {
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.25)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25)); }

.filter-black-progression-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.4)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.4)); }

.filter-black-progression-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.55)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.55)); }

.filter-black-progression-4 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(rgba(0, 0, 0, 0.65)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.65)); }

#quarter-content-boxes-container {
  background: #fbfbf8;
  border-top: 5px solid red;
  width: 100vw;
  padding-top: 225px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: 790px; }
  #quarter-content-boxes-container .quarter-content-boxes {
    position: relative;
    display: inline-block;
    height: auto;
    min-height: 30vh;
    width: 24.75vw;
    min-width: 320px;
    border: 5px solid red;
    background: radial-gradient(#00445f, #006f8b);
    text-align: center;
    overflow: hidden !important;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    cursor: pointer; }
    #quarter-content-boxes-container .quarter-content-boxes:hover {
      background: #e21214;
      border: 8px solid #006f8b; }
    #quarter-content-boxes-container .quarter-content-boxes h1, #quarter-content-boxes-container .quarter-content-boxes h2, #quarter-content-boxes-container .quarter-content-boxes h3, #quarter-content-boxes-container .quarter-content-boxes p {
      color: white;
      padding: 5px; }
    #quarter-content-boxes-container .quarter-content-boxes h2 {
      font-size: 2.1em; }
    #quarter-content-boxes-container .quarter-content-boxes p {
      font-size: 1.5em; }
    @media screen and (max-width: 768px) {
      #quarter-content-boxes-container .quarter-content-boxes {
        width: 49.75vw; } }
    @media screen and (max-width: 414px) {
      #quarter-content-boxes-container .quarter-content-boxes {
        width: 100%; } }
  @media screen and (max-width: 414px) {
    #quarter-content-boxes-container {
      padding-top: 100px;
      height: 1100px;
      margin-top: -300px; } }

.margin-top-installations {
  margin-top: 30px; }

.modal-header-sticky-overlay {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
  padding: 0px 10px;
  width: 100vw;
  min-height: 100px;
  overflow: hidden;
  background-color: rgba(0, 111, 139, 0.4);
  border: 5px solid red; }
  .modal-header-sticky-overlay h1 {
    color: #fbfbf8;
    text-shadow: 2px 2px 5px black;
    font-size: 86px; }

/* The Quarter Content Modal (background) */
.quarter-content-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8);
  /* Black w/ opacity */ }

/* Quarter Modal Content box */
.quarter-modal-content-box {
  background: radial-gradient(#00445f, #006f8b);
  border: 3px solid #e21214;
  margin: auto;
  overflow: scroll;
  padding-top: 98px;
  width: 50vw;
  height: 100%;
  z-index: 11; }
  .quarter-modal-content-box h2 {
    background-color: #00445f;
    line-height: 100px;
    color: white; }
  .quarter-modal-content-box h3 {
    padding-left: 10px;
    color: #f0eaea;
    -webkit-text-decoration: underline #e21214;
    text-decoration: underline #e21214;
    letter-spacing: 1px; }
  .quarter-modal-content-box p {
    padding: 14px;
    text-align: justify;
    color: white; }
  @media screen and (max-width: 768px) {
    .quarter-modal-content-box {
      width: 90vw;
      border-radius: 100px 100px 0px 0px; }
      .quarter-modal-content-box h2 {
        line-height: 50px;
        background: none; } }

/* The Quarter Content Modal Close Button */
.close-quarter-content-modal {
  color: rgba(226, 18, 20, 0.4);
  text-shadow: #000 0px 2px;
  float: right;
  font-size: 58px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  border: 2px dotted #dacccc; }

.close-quarter-content-modal:hover,
.close-quarter-content-modal:focus {
  color: #e21214;
  text-decoration: none;
  cursor: pointer; }

/* -------------------------------- Full Screen Height, Half Screen Width Divs --------------------------------------*/
.fifty-fifty-ImageWrapper {
  position: relative;
  display: inline-block;
  height: calc(100vh - 175px);
  width: 50vw;
  overflow: hidden;
  cursor: pointer; }
  .fifty-fifty-ImageWrapper #fifty-fifty-left-img {
    background-image: url("/images/index/five-star-power-systems-HVAC-Greyscale-background.jpg");
    height: calc(100vh - 175px);
    /*Transition*/
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease; }
    .fifty-fifty-ImageWrapper #fifty-fifty-left-img img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      margin-top: 0px;
      padding-top: 150px; }
      @media screen and (max-width: 1024px) {
        .fifty-fifty-ImageWrapper #fifty-fifty-left-img img {
          padding-top: 300px; } }
      @media screen and (max-width: 414px) {
        .fifty-fifty-ImageWrapper #fifty-fifty-left-img img {
          padding-top: 100px; } }
    .fifty-fifty-ImageWrapper #fifty-fifty-left-img img.image-mobile {
      display: none; }
    @media screen and (max-width: 414px) {
      .fifty-fifty-ImageWrapper #fifty-fifty-left-img img.image-desktop {
        display: none; }
      .fifty-fifty-ImageWrapper #fifty-fifty-left-img img.image-mobile {
        display: block; } }
    @media screen and (max-width: 414px) {
      .fifty-fifty-ImageWrapper #fifty-fifty-left-img {
        background-image: url("/images/index/five-star-power-systems-HVAC-Greyscale-background-mobile.jpg");
        height: calc(100vh - 87.5px); } }
  @media screen and (max-width: 414px) {
    .fifty-fifty-ImageWrapper {
      height: calc(100vh - 87.5px); } }
  .fifty-fifty-ImageWrapper #fifty-fifty-right-img {
    background-image: url("/images/index/five-star-power-systems-Generator-Greyscale-background.jpg");
    height: calc(100vh - 175px);
    /*Transition*/
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease; }
    .fifty-fifty-ImageWrapper #fifty-fifty-right-img img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      padding-top: 150px; }
      @media screen and (max-width: 1024px) {
        .fifty-fifty-ImageWrapper #fifty-fifty-right-img img {
          padding-top: 300px; } }
      @media screen and (max-width: 414px) {
        .fifty-fifty-ImageWrapper #fifty-fifty-right-img img {
          padding-top: 115px; } }
    .fifty-fifty-ImageWrapper #fifty-fifty-right-img img.image-mobile {
      display: none; }
    @media screen and (max-width: 414px) {
      .fifty-fifty-ImageWrapper #fifty-fifty-right-img img.image-desktop {
        display: none; }
      .fifty-fifty-ImageWrapper #fifty-fifty-right-img img.image-mobile {
        display: block; } }
    @media screen and (max-width: 414px) {
      .fifty-fifty-ImageWrapper #fifty-fifty-right-img {
        background-image: url("/images/index/five-star-power-systems-Generator-Greyscale-background-mobile.jpg");
        height: calc(100vh - 87.5px); } }
  @media screen and (max-width: 414px) {
    .fifty-fifty-ImageWrapper {
      height: calc(100vh - 87.5px); } }

.fifty-fifty-title-overlay {
  position: absolute;
  bottom: 42vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 10px;
  height: 75px;
  width: 100%;
  min-height: 10%;
  overflow: hidden; }
  @media screen and (max-width: 414px) {
    .fifty-fifty-title-overlay {
      bottom: 35vh; } }
  .fifty-fifty-title-overlay h1 {
    font-size: 2.5em;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: .5em;
    padding: 0px 5px; }
    @media screen and (max-width: 414px) {
      .fifty-fifty-title-overlay h1 {
        font-size: 2.0em; } }

#fifty-fifty-left-img:hover {
  opacity: 1;
  background-image: url("/images/index/five-star-power-systems-HVAC-Color-background.jpg");
  /*background-size:cover;
    background-position:center;
    */
  background-size: 100%;
  color: #ED2E14;
  background-color: transparent;
  /*Glow*/
  -webkit-box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8); }
  #fifty-fifty-left-img:hover h1 {
    color: white;
    font-size: 4.5em;
    background-color: rgba(0, 0, 0, 0.5); }
  @media screen and (max-width: 414px) {
    #fifty-fifty-left-img:hover h1 {
      font-size: 3.0em; } }
  @media screen and (max-width: 414px) {
    #fifty-fifty-left-img:hover {
      background-image: url("/images/index/five-star-power-systems-HVAC-Color-background-mobile.jpg"); } }

#fifty-fifty-right-img:hover {
  opacity: 1;
  background-image: url("/images/index/five-star-power-systems-Generator-Color-background.jpg");
  /*background-size:cover;
    background-position:center;
    */
  background-size: 100%;
  background-color: transparent;
  /*Glow*/
  -webkit-box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8); }
  #fifty-fifty-right-img:hover h1 {
    color: white;
    font-size: 4.5em;
    background-color: rgba(0, 0, 0, 0.5); }
  @media screen and (max-width: 414px) {
    #fifty-fifty-right-img:hover h1 {
      font-size: 3.0em; } }
  @media screen and (max-width: 414px) {
    #fifty-fifty-right-img:hover {
      background-image: url("/images/index/five-star-power-systems-Generator-Color-background-mobile.jpg"); } }

a.fill-div {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  background: transparent; }

/* ------------------------------------ CLIP-PATHS ---------------------------------- */
.FivepointStar {
  margin-top: 175px;
  margin-left: auto;
  margin-right: auto;
  -webkit-clip-path: polygon(50% 2%, 61% 35%, 96% 35%, 68% 57%, 79% 89%, 50% 70%, 21% 89%, 32% 57%, 4% 35%, 39% 35%);
  clip-path: polygon(50% 2%, 61% 35%, 96% 35%, 68% 57%, 79% 89%, 50% 70%, 21% 89%, 32% 57%, 4% 35%, 39% 35%);
  max-width: 1000px; }

.section-overlay {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 150px);
  background: #e2121433;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -10; }

.grid {
  display: grid; }

.grid-box {
  background-color: rgba(39, 39, 39, 0.9);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(50px, auto));
  gap: 0 1rem;
  padding: 50px 20px 60px 20px;
  margin: 60px 50px 20px 50px;
  border-radius: 5px; }
  @media screen and (max-width: 767px) {
    .grid-box {
      margin: 40px 15px 20px 15px;
      gap: 0; } }

.err {
  padding: .75rem 15%;
  color: rgba(0, 0, 0, 0.8); }
  @media screen and (min-width: 415px) and (max-width: 765px) {
    .err {
      padding: .75rem .25rem; } }

.err-header {
  grid-column: 1 / span 2;
  text-align: center;
  font-size: 1.50em;
  letter-spacing: .25em;
  padding: 2rem 12%;
  background-color: #fffff8;
  border: 6px solid #e21214;
  border-radius: 5px 5px 1px 1px; }
  @media screen and (max-width: 1578px) {
    .err-header {
      line-height: 1.65em; } }
  @media screen and (min-width: 415px) and (max-width: 765px) {
    .err-header {
      max-width: 470px; } }
  @media screen and (max-width: 415px) {
    .err-header {
      letter-spacing: .15em;
      font-size: 1.4em;
      padding: 1rem 1rem;
      grid-column: 1 / span 1; } }

.flt-hdr {
  text-align: center;
  font-size: 1.5em;
  letter-spacing: .15em;
  border-bottom: solid 1px #272727; }

.subg-wrppr {
  display: grid;
  grid-template-columns: subgrid;
  background-color: #fffff8;
  border: 3px solid #e21214;
  border-radius: 5px; }

.err-sltn-bckgrnd {
  background-image: url("/images/generators/error-code-grid/generator-error-code-solution-background.jpg");
  background-repeat: no-repeat;
  background-position: center; }

.gap-mrgn {
  margin-top: 1rem; }

.causes p {
  text-align: left;
  line-height: 1.35em; }

.causes p.r8t {
  font-size: 2em;
  letter-spacing: .15em;
  text-align: center;
  margin-top: 30px; }
  @media screen and (max-width: 800px) {
    .causes p.r8t {
      font-size: 1.8em; } }

.espn2 {
  grid-column: 1 / span 2;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.e1 {
  grid-column: 1 / span 1; }

.e2 {
  grid-column: 2 / span 1; }
  @media screen and (max-width: 500px) {
    .e2 {
      grid-column: 1 / span 1; } }

.frst {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.scnd {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.thrd {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }
  @media screen and (max-width: 500px) {
    .thrd {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; } }

.frth {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }
  @media screen and (max-width: 500px) {
    .frth {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; } }

.ffth {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }
  @media screen and (max-width: 500px) {
    .ffth {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; } }

.sxth {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }
  @media screen and (max-width: 500px) {
    .sxth {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; } }

.svnth {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }
  @media screen and (max-width: 500px) {
    .svnth {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; } }

.eghth {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }
  @media screen and (max-width: 500px) {
    .eghth {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; } }

.nnth {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

a.err-button {
  display: inline-block;
  padding: 0.45em 1.2em;
  border: 0.1em solid #FFFFFF;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #FFFFFF;
  text-align: center;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  letter-spacing: .10em; }

a.err-button:hover {
  color: #000000;
  background-color: #FFFFFF; }

@media all and (max-width: 30em) {
   a.err-button {
    display: block;
    margin: 0.4em auto; } }

.btt-pd {
  padding: .75rem 40%;
  margin-top: 40px; }
  @media screen and (max-width: 1230px) {
    .btt-pd {
      padding: .75rem 20%; } }
  @media screen and (min-width: 415px) and (max-width: 765px) {
    .btt-pd {
      padding: .75rem 10%; } }
  @media screen and (max-width: 414px) {
    .btt-pd {
      padding: .75rem 5%; } }

#logo-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  z-index: 10; }
  #logo-wrapper #logo {
    position: absolute;
    top: 0px;
    margin-left: calc(50% - (322px / 2));
    cursor: pointer;
    overflow: hidden; }
    #logo-wrapper #logo img {
      display: block;
      height: 100%;
      width: 100%; }
  #logo-wrapper .large {
    background-color: white;
    padding: 0px;
    width: 322px;
    height: 322px;
    border: 4px solid #e21214;
    border-radius: 30%;
    -webkit-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2); }
  #logo-wrapper .small {
    width: 162px;
    height: 70px; }
  @media screen and (max-width: 768px) {
    #logo-wrapper {
      width: 45%; }
      #logo-wrapper .large {
        width: 275px;
        height: 275px; } }
  @media screen and (max-width: 745px), screen and (max-height: 480px) {
    #logo-wrapper {
      width: 161px; }
      #logo-wrapper #logo {
        margin-left: 0px; }
      #logo-wrapper .large {
        padding: 0px;
        width: 125px;
        height: 125px; } }
  @media screen and (max-width: 300px) {
    #logo-wrapper {
      width: 75px; } }

#menu-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px; }
  #menu-icon #bar1,
  #menu-icon #bar2,
  #menu-icon #bar3 {
    width: 32px;
    height: 5px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  #menu-icon #bar1 {
    margin: 5px 0px 0px;
    background-color: black; }
  #menu-icon #bar2 {
    margin: 5px 0px;
    background-color: #2e2525; }
  #menu-icon #bar3 {
    margin: 0px 0px 5px;
    background-color: black; }
  #menu-icon.open #bar1,
  #menu-icon.open #bar3 {
    margin: 0px;
    padding: 0px;
    height: 0px;
    opacity: 0; }
  #menu-icon.open #bar2 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 5px solid black;
    border-left: 5px solid black;
    width: 24px;
    height: 24px;
    background-color: transparent;
    -webkit-transform: rotate(45deg) translate(3px, 3px);
    transform: rotate(45deg) translate(3px, 3px); }

#nav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  height: 100%; }
  #nav-menu #menu-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 40px; }
    #nav-menu #menu-icon #bar1,
    #nav-menu #menu-icon #bar2,
    #nav-menu #menu-icon #bar3 {
      width: 32px;
      height: 5px;
      -webkit-transition-duration: .3s;
      transition-duration: .3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out; }
    #nav-menu #menu-icon #bar1 {
      margin: 5px 0px 0px;
      background-color: black; }
    #nav-menu #menu-icon #bar2 {
      margin: 5px 0px;
      background-color: #2e2525; }
    #nav-menu #menu-icon #bar3 {
      margin: 0px 0px 5px;
      background-color: black; }
    #nav-menu #menu-icon.open #bar1,
    #nav-menu #menu-icon.open #bar3 {
      margin: 0px;
      padding: 0px;
      height: 0px;
      opacity: 0; }
    #nav-menu #menu-icon.open #bar2 {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-top: 5px solid black;
      border-left: 5px solid black;
      width: 24px;
      height: 24px;
      background-color: transparent;
      -webkit-transform: rotate(45deg) translate(3px, 3px);
      transform: rotate(45deg) translate(3px, 3px); }
  #nav-menu #menu-icon {
    display: none; }
  #nav-menu #menu-phone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10px; }
    @media screen and (min-width: 570px) {
      #nav-menu #menu-phone {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        padding-right: 22px; } }
  #nav-menu #site-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    #nav-menu #site-links .siteLink {
      margin: 3px 12px 2px 12px;
      color: #2e2525;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer; }
      #nav-menu #site-links .siteLink:hover {
        color: #e21214; }
  @media screen and (max-width: 745px), screen and (max-height: 480px) {
    #nav-menu {
      width: calc(100% - (322px / 2.0)); }
      #nav-menu #site-links .siteLink {
        margin: 8px; } }
  @media screen and (max-width: 570px) {
    #nav-menu {
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap; }
      #nav-menu #menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 5px; }
      #nav-menu #site-links {
        position: absolute;
        top: 87.5px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        height: 0px;
        display: none;
        background: #2c2929;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px; }
        #nav-menu #site-links .siteLink {
          margin: 10px 10px;
          padding: 5px;
          width: 120px;
          border-radius: 30px;
          background: -webkit-gradient(linear, left top, left bottom, from(#fbfbf8), to(rgba(46, 37, 37, 0.9)));
          background: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.9));
          -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
          #nav-menu #site-links .siteLink.active {
            background: linear-gradient(to top, #fbfbf8, #D0D0D0); } }
  @media screen and (max-width: 300px) {
    #nav-menu {
      width: calc(100% - 75px); }
      #nav-menu #menu-icon {
        -webkit-transform: translateX(calc((100vw - 75px - 20px) / 2 - 20px));
        transform: translateX(calc((100vw - 75px - 20px) / 2 - 20px)); }
      #nav-menu #site-links {
        top: 70px;
        -webkit-transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px));
        transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px)); } }

/* ----------------------------------- Menu Dropdown --------------------------*/
@media screen and (max-width: 570px) {
  .dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap; } }

.dropbtn {
  background: none;
  color: #2e2525;
  padding: 0px;
  font-size: 16px;
  border: none; }

.dropdown-content {
  display: none;
  position: absolute;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(46, 37, 37, 0)), color-stop(5%, #f8f8f4), to(#f5f0f0));
  background-image: linear-gradient(to bottom, rgba(46, 37, 37, 0), #f8f8f4 5%, #f5f0f0);
  border-left: 3px solid rgba(226, 18, 20, 0.4);
  border-bottom: 3px solid #e21214;
  border-right: 3px solid rgba(226, 18, 20, 0.4);
  border-radius: 0px 30px 10px 15px;
  min-width: 140px;
  z-index: 0; }
  .dropdown-content li {
    list-style-type: none;
    text-align: left;
    font-size: .80em; }
  @media screen and (max-width: 570px) {
    .dropdown-content {
      border-radius: 0px 0px 30px 30px;
      top: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      right: 100%;
      padding-top: 50px;
      padding-bottom: 10px;
      position: absolute;
      background: rgba(0, 0, 0, 0.9);
      width: 130px; }
      .dropdown-content li {
        height: 44px;
        text-align: center;
        background: -webkit-gradient(linear, left top, left bottom, from(#fbfbf8), to(rgba(46, 37, 37, 0.9)));
        background: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.9));
        border: solid #1f1e1e;
        border-radius: 30px;
        margin: 20px 5px; } }

.dropdown-content a {
  color: #2e2525;
  border-radius: 10px;
  padding: 12px 12px;
  text-decoration: none;
  display: block; }

.dropdown-content a:hover {
  background-color: rgba(46, 37, 37, 0.4);
  color: #e21214; }

.siteLink.dropbtn.subPbtn:hover .dropdown-content {
  display: block; }

#top-overlay {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 175px;
  z-index: 4;
  background: #fbfbf8;
  background-image: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.1));
  -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 745px), screen and (max-height: 480px) {
    #top-overlay {
      height: 87.5px; } }
  @media screen and (max-width: 300px) {
    #top-overlay {
      height: 70px; } }
  #top-overlay #overlay-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    padding: 0px 10px;
    /* ----------------------------------- Menu Dropdown --------------------------*/ }
    #top-overlay #overlay-container #logo-wrapper {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 50%;
      z-index: 10; }
      #top-overlay #overlay-container #logo-wrapper #logo {
        position: absolute;
        top: 0px;
        margin-left: calc(50% - (322px / 2));
        cursor: pointer;
        overflow: hidden; }
        #top-overlay #overlay-container #logo-wrapper #logo img {
          display: block;
          height: 100%;
          width: 100%; }
      #top-overlay #overlay-container #logo-wrapper .large {
        background-color: white;
        padding: 0px;
        width: 322px;
        height: 322px;
        border: 4px solid #e21214;
        border-radius: 30%;
        -webkit-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2); }
      #top-overlay #overlay-container #logo-wrapper .small {
        width: 162px;
        height: 70px; }
      @media screen and (max-width: 768px) {
        #top-overlay #overlay-container #logo-wrapper {
          width: 45%; }
          #top-overlay #overlay-container #logo-wrapper .large {
            width: 275px;
            height: 275px; } }
      @media screen and (max-width: 745px), screen and (max-height: 480px) {
        #top-overlay #overlay-container #logo-wrapper {
          width: 161px; }
          #top-overlay #overlay-container #logo-wrapper #logo {
            margin-left: 0px; }
          #top-overlay #overlay-container #logo-wrapper .large {
            padding: 0px;
            width: 125px;
            height: 125px; } }
      @media screen and (max-width: 300px) {
        #top-overlay #overlay-container #logo-wrapper {
          width: 75px; } }
    #top-overlay #overlay-container #nav-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 50%;
      height: 100%; }
      #top-overlay #overlay-container #nav-menu #menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 40px;
        height: 40px; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar1,
        #top-overlay #overlay-container #nav-menu #menu-icon #bar2,
        #top-overlay #overlay-container #nav-menu #menu-icon #bar3 {
          width: 32px;
          height: 5px;
          -webkit-transition-duration: .3s;
          transition-duration: .3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar1 {
          margin: 5px 0px 0px;
          background-color: black; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar2 {
          margin: 5px 0px;
          background-color: #2e2525; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar3 {
          margin: 0px 0px 5px;
          background-color: black; }
        #top-overlay #overlay-container #nav-menu #menu-icon.open #bar1,
        #top-overlay #overlay-container #nav-menu #menu-icon.open #bar3 {
          margin: 0px;
          padding: 0px;
          height: 0px;
          opacity: 0; }
        #top-overlay #overlay-container #nav-menu #menu-icon.open #bar2 {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-top: 5px solid black;
          border-left: 5px solid black;
          width: 24px;
          height: 24px;
          background-color: transparent;
          -webkit-transform: rotate(45deg) translate(3px, 3px);
          transform: rotate(45deg) translate(3px, 3px); }
      #top-overlay #overlay-container #nav-menu #menu-icon {
        display: none; }
      #top-overlay #overlay-container #nav-menu #menu-phone {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-right: 10px; }
        @media screen and (min-width: 570px) {
          #top-overlay #overlay-container #nav-menu #menu-phone {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            padding-right: 22px; } }
      #top-overlay #overlay-container #nav-menu #site-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        #top-overlay #overlay-container #nav-menu #site-links .siteLink {
          margin: 3px 12px 2px 12px;
          color: #2e2525;
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          text-decoration: none;
          white-space: nowrap;
          cursor: pointer; }
          #top-overlay #overlay-container #nav-menu #site-links .siteLink:hover {
            color: #e21214; }
      @media screen and (max-width: 745px), screen and (max-height: 480px) {
        #top-overlay #overlay-container #nav-menu {
          width: calc(100% - (322px / 2.0)); }
          #top-overlay #overlay-container #nav-menu #site-links .siteLink {
            margin: 8px; } }
      @media screen and (max-width: 570px) {
        #top-overlay #overlay-container #nav-menu {
          position: relative;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
          flex-flow: column nowrap; }
          #top-overlay #overlay-container #nav-menu #menu-icon {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-top: 5px; }
          #top-overlay #overlay-container #nav-menu #site-links {
            position: absolute;
            top: 87.5px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
            height: 0px;
            display: none;
            background: #2c2929;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px; }
            #top-overlay #overlay-container #nav-menu #site-links .siteLink {
              margin: 10px 10px;
              padding: 5px;
              width: 120px;
              border-radius: 30px;
              background: -webkit-gradient(linear, left top, left bottom, from(#fbfbf8), to(rgba(46, 37, 37, 0.9)));
              background: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.9));
              -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
              box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
              #top-overlay #overlay-container #nav-menu #site-links .siteLink.active {
                background: linear-gradient(to top, #fbfbf8, #D0D0D0); } }
      @media screen and (max-width: 300px) {
        #top-overlay #overlay-container #nav-menu {
          width: calc(100% - 75px); }
          #top-overlay #overlay-container #nav-menu #menu-icon {
            -webkit-transform: translateX(calc((100vw - 75px - 20px) / 2 - 20px));
            transform: translateX(calc((100vw - 75px - 20px) / 2 - 20px)); }
          #top-overlay #overlay-container #nav-menu #site-links {
            top: 70px;
            -webkit-transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px));
            transform: translateX(calc((100vw - 75px - 20px) / 2 - 50px)); } }
    @media screen and (max-width: 570px) {
      #top-overlay #overlay-container .dropdown {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap; } }
    #top-overlay #overlay-container .dropbtn {
      background: none;
      color: #2e2525;
      padding: 0px;
      font-size: 16px;
      border: none; }
    #top-overlay #overlay-container .dropdown-content {
      display: none;
      position: absolute;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(46, 37, 37, 0)), color-stop(5%, #f8f8f4), to(#f5f0f0));
      background-image: linear-gradient(to bottom, rgba(46, 37, 37, 0), #f8f8f4 5%, #f5f0f0);
      border-left: 3px solid rgba(226, 18, 20, 0.4);
      border-bottom: 3px solid #e21214;
      border-right: 3px solid rgba(226, 18, 20, 0.4);
      border-radius: 0px 30px 10px 15px;
      min-width: 140px;
      z-index: 0; }
      #top-overlay #overlay-container .dropdown-content li {
        list-style-type: none;
        text-align: left;
        font-size: .80em; }
      @media screen and (max-width: 570px) {
        #top-overlay #overlay-container .dropdown-content {
          border-radius: 0px 0px 30px 30px;
          top: 0px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
          right: 100%;
          padding-top: 50px;
          padding-bottom: 10px;
          position: absolute;
          background: rgba(0, 0, 0, 0.9);
          width: 130px; }
          #top-overlay #overlay-container .dropdown-content li {
            height: 44px;
            text-align: center;
            background: -webkit-gradient(linear, left top, left bottom, from(#fbfbf8), to(rgba(46, 37, 37, 0.9)));
            background: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.9));
            border: solid #1f1e1e;
            border-radius: 30px;
            margin: 20px 5px; } }
    #top-overlay #overlay-container .dropdown-content a {
      color: #2e2525;
      border-radius: 10px;
      padding: 12px 12px;
      text-decoration: none;
      display: block; }
    #top-overlay #overlay-container .dropdown-content a:hover {
      background-color: rgba(46, 37, 37, 0.4);
      color: #e21214; }
    #top-overlay #overlay-container .siteLink.dropbtn.subPbtn:hover .dropdown-content {
      display: block; }

#footer-wrapper {
  width: 100vw;
  height: 150px;
  background: linear-gradient(to top, black, #1F1F1F); }
  #footer-wrapper #footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 20px;
    margin: 0px auto;
    max-width: 960px;
    height: 100%; }
    #footer-wrapper #footer small {
      color: white;
      text-align: center; }
      #footer-wrapper #footer small a {
        color: #e21214; }
      #footer-wrapper #footer small img {
        margin: 0px 10px; }

.footer-full-width-element-container {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 33%;
  width: 100%; }

#bottom-overlay {
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  padding: 5px 0px;
  background: linear-gradient(to top, black, #1F1F1F);
  z-index: 10; }
  #bottom-overlay button {
    margin: 0px 8px;
    padding: 5px 0px;
    width: 110px;
    height: auto;
    background: -webkit-gradient(linear, left bottom, left top, from(#e21214), to(#f25e5f));
    background: linear-gradient(to top, #e21214, #f25e5f);
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 1.0em;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden; }
    #bottom-overlay button:active {
      background: -webkit-gradient(linear, left top, left bottom, from(#e21214), to(#b30e10));
      background: linear-gradient(to bottom, #e21214, #b30e10); }
  @media screen and (max-width: 570px), screen and (max-height: 480px) {
    #bottom-overlay button {
      margin: 0px 4px;
      font-size: 18px; } }

#home-content-wrapper #description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 50px 10px;
  height: 350px;
  background-color: rgba(226, 18, 20, 0.4);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); }
  #home-content-wrapper #description h2 {
    text-align: center;
    font-size: 32px;
    color: white; }
  #home-content-wrapper #description a {
    color: #e21214;
    text-decoration: none; }
    #home-content-wrapper #description a:hover {
      text-decoration: underline; }
  #home-content-wrapper #description p {
    color: whitesmoke;
    max-width: 900px;
    text-align: center;
    font-size: 20px; }
  @media screen and (max-width: 770px) {
    #home-content-wrapper #description {
      height: 500px; } }

#home-content-wrapper #video-area {
  padding: 40px;
  width: 100%;
  background-color: #e21214; }
  #home-content-wrapper #video-area #video-wrapper {
    margin: 0px auto;
    width: 100%;
    max-width: 968px;
    height: 100%;
    border: 4px solid #e21214; }
    #home-content-wrapper #video-area #video-wrapper video {
      display: block;
      width: 100%;
      outline: none; }

#home-content-wrapper #mission-statement {
  margin: 80px auto; }

#home-content-wrapper h2 {
  margin: 20px auto;
  padding: 0px 40px;
  text-align: center;
  font-size: 32px; }
  @media screen and (max-width: 745px) {
    #home-content-wrapper h2 {
      font-size: 24px; } }

#home-content-wrapper p {
  font-size: 24px;
  margin: 20px auto;
  padding: 0px 40px; }

@media screen and (max-width: 570px), screen and (max-height: 480px) {
  #home-content-wrapper #description h2 {
    font-size: 24px; }
  #home-content-wrapper #video-area {
    padding: 20px; }
  #home-content-wrapper #mission-statement {
    margin: 40px auto; } }

@media screen and (max-width: 270px) {
  #home-content-wrapper #description {
    height: 100%; } }

#background-banner {
  position: fixed;
  top: 0px;
  height: calc(100vh - 0px);
  z-index: -1; }

#background-content-wrapper {
  margin: 0px auto;
  width: 100%;
  max-width: 960px;
  height: 100%;
  padding: 172px 20px 0px; }
  #background-content-wrapper #inner-page-links {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
    #background-content-wrapper #inner-page-links button {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 30px;
      padding: 20px;
      font-size: 24px;
      height: 69px;
      width: 100%;
      max-width: 240px;
      border: 3px solid rgba(226, 18, 20, 0.8);
      border-radius: 15px;
      background: #fbfbf8;
      background-image: radial-gradient(rgba(184, 171, 171, 0.2), rgba(251, 251, 248, 0.5));
      font-family: 'Roboto Condensed', sans-serif; }
      #background-content-wrapper #inner-page-links button:hover {
        background-color: rgba(46, 37, 37, 0.4);
        background-image: none;
        color: #e21214;
        border: 5px solid #e21214;
        text-shadow: 1px 1px black; }
  #background-content-wrapper #bottom-overlay {
    display: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    padding: 5px 0px;
    background: linear-gradient(to top, black, #1F1F1F);
    z-index: 10; }
    #background-content-wrapper #bottom-overlay button {
      margin: 0px 8px;
      padding: 5px 0px;
      width: 110px;
      height: auto;
      background: -webkit-gradient(linear, left bottom, left top, from(#e21214), to(#f25e5f));
      background: linear-gradient(to top, #e21214, #f25e5f);
      color: white;
      font-weight: bold;
      font-size: 20px;
      border-radius: 1.0em;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden; }
      #background-content-wrapper #bottom-overlay button:active {
        background: -webkit-gradient(linear, left top, left bottom, from(#e21214), to(#b30e10));
        background: linear-gradient(to bottom, #e21214, #b30e10); }
    @media screen and (max-width: 570px), screen and (max-height: 480px) {
      #background-content-wrapper #bottom-overlay button {
        margin: 0px 4px;
        font-size: 18px; } }
  #background-content-wrapper section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    margin: 20px 0px;
    padding: 10px;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    background: #fbfbf8;
    background-image: radial-gradient(rgba(184, 171, 171, 0.1), rgba(251, 251, 248, 0.9)); }
    #background-content-wrapper section a:hover {
      color: #e21214; }
    #background-content-wrapper section h1 {
      font-size: 48px;
      margin: 20px 10px; }
    #background-content-wrapper section h2 {
      font-size: 36px;
      margin: 20px 10px 0px; }
    #background-content-wrapper section h3 {
      font-size: 24px;
      margin: 0px 10px 0px; }
    #background-content-wrapper section hr {
      height: 2px;
      background-color: #e21214;
      border: none;
      margin: 0px 10px; }
    #background-content-wrapper section P {
      margin: 20px 17px;
      height: 100%;
      font-size: 22px;
      font-weight: bold;
      text-align: justify; }
    #background-content-wrapper section img {
      display: block;
      margin: 10px auto 0px;
      width: 100%;
      border: none; }
    #background-content-wrapper section img.center {
      margin-left: auto;
      margin-right: auto; }
    #background-content-wrapper section .specialtyButton {
      display: block;
      margin: 10px 0px;
      padding: 10px;
      height: 100px;
      line-height: 80px;
      border: 3px solid rgba(226, 18, 20, 0.2);
      border-radius: 30px;
      -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      background: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.05));
      text-align: center;
      cursor: pointer; }
      #background-content-wrapper section .specialtyButton:hover {
        background: #e21214;
        color: white; }
      #background-content-wrapper section .specialtyButton:active {
        background: #e21214; }
    #background-content-wrapper section .specialtyBlurb {
      margin: 0px 10px;
      font-size: 18px;
      height: 0px;
      overflow: hidden; }
      #background-content-wrapper section .specialtyBlurb img {
        margin: 10px 0px; }
    #background-content-wrapper section ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      height: 0px;
      overflow: hidden; }
      #background-content-wrapper section ul li {
        text-align: center;
        margin: 10px;
        padding: 10px;
        list-style: none;
        font-weight: bold;
        width: auto;
        min-width: 155px;
        height: auto;
        background-color: #fbfbf8;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 30px; }
  @media screen and (max-width: 414px), screen and (max-height: 480px) {
    #background-content-wrapper {
      padding-top: 62.5px; }
      #background-content-wrapper #inner-page-links button {
        margin: 0px;
        padding: 5px;
        font-size: 18px;
        max-width: 100px; }
      #background-content-wrapper section h1 {
        font-size: 32px;
        text-align: left; }
      #background-content-wrapper section h2 {
        font-size: 22px; }
      #background-content-wrapper section h3 {
        font-size: 18px; }
      #background-content-wrapper section p {
        font-size: 16px; } }
  @media screen and (max-width: 300px) {
    #background-content-wrapper {
      padding-top: 90px; }
      #background-content-wrapper section h1 {
        font-size: 24px;
        text-align: left; }
      #background-content-wrapper section h2 {
        font-size: 20px; }
      #background-content-wrapper section h3 {
        font-size: 16px; }
      #background-content-wrapper section p {
        font-size: 14px; } }

#installations-content-wrapper {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0; }
  #installations-content-wrapper #transparent-content-wrapper {
    width: 100%;
    background-color: rgba(251, 251, 248, 0.45);
    border-top: 1rem solid rgba(0, 0, 0, 0);
    border-bottom: 1rem solid rgba(0, 0, 0, 0);
    margin-bottom: 30px; }
    #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: 20px auto;
      width: 100%;
      max-width: 960px;
      height: 100%; }
      #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container .sectionButton {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 30px;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: #333;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        height: 160px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 10px;
        padding: 10px;
        width: 100%;
        max-width: 300px; }
        #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container .sectionButton img {
          display: block;
          width: 100%;
          max-width: 140px;
          height: 100%;
          max-height: 140px;
          border-radius: 50%;
          border: 4px solid #e21214;
          -webkit-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2);
          overflow: hidden; }
        #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container .sectionButton span {
          margin-left: 10px;
          width: 100%;
          max-width: 130px;
          font-weight: bold;
          font-size: 22px;
          text-align: left; }
      @media screen and (max-width: 745px), screen and (max-height: 480px) {
        #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container .sectionButton {
          max-width: 230px;
          height: 110px; }
          #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container .sectionButton img {
            max-width: 70px;
            max-height: 70px; }
          #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container .sectionButton span {
            font-size: 18px; } }
      @media screen and (max-width: 270px) {
        #installations-content-wrapper #transparent-content-wrapper #inner-nav-button-container .sectionButton span {
          font-size: 14px; } }
    #installations-content-wrapper #transparent-content-wrapper section {
      margin: 0px auto;
      padding: 40px 0px;
      width: 100%;
      max-width: 960px; }
      #installations-content-wrapper #transparent-content-wrapper section h2 {
        padding: 0px 10px;
        margin: 0px auto 20px;
        font-size: 32px;
        text-align: center; }
        @media screen and (max-width: 570px), screen and (max-height: 480px) {
          #installations-content-wrapper #transparent-content-wrapper section h2 {
            font-size: 26px; } }

#backgroundVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  overflow: hidden;
  z-index: -10; }
  @media screen and (max-width: 765px) {
    #backgroundVideo {
      display: none; } }

.background-image {
  display: none; }
  @media screen and (max-width: 765px) {
    .background-image {
      position: fixed;
      display: block;
      height: auto;
      width: auto;
      z-index: -10; } }

#services-banner {
  position: fixed;
  height: 120vh;
  z-index: -3; }
  @media (max-width: 745px) {
    #services-banner {
      top: 150px; } }

#background-video-header-banner-full {
  position: relative;
  height: 100vh; }

#background-video-title-banner {
  position: relative;
  height: auto; }

/*---------- STICKY BANNER SECTION ----------*/
#sticky-banner-small-menu-stop {
  position: -webkit-sticky;
  position: sticky;
  top: 175px;
  height: 900px; }
  @media screen and (max-width: 414px) {
    #sticky-banner-small-menu-stop {
      Top: 150px; } }

#full-page-sticky-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  max-width: 960px;
  height: 100%;
  min-height: 50vh;
  width: 100%;
  border-radius: 20px; }
  #full-page-sticky-content h1, #full-page-sticky-content h2, #full-page-sticky-content h3, #full-page-sticky-content p {
    margin: auto;
    text-align: center;
    padding: 30px; }
  #full-page-sticky-content h1, #full-page-sticky-content p {
    color: white;
    text-shadow: 1px 4px black; }
  #full-page-sticky-content h1 {
    font-size: 4em; }
  #full-page-sticky-content h2 {
    color: #e21214;
    text-shadow: 1px 4px black;
    font-size: 3.25em; }
  #full-page-sticky-content h3 {
    color: #2e2525;
    font-size: 2.75em; }
  #full-page-sticky-content p {
    font-size: 1.50em;
    border-width: 25px 20px 25px 20px;
    border-style: solid;
    border-color: white; }

.sticky-content-background {
  background: rgba(255, 255, 255, 0.8);
  margin: auto;
  color: black; }

#services-wrapper-space {
  margin: 0px auto;
  width: 100%;
  max-width: 960px;
  height: 0px; }

#services-content-wrapper {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0; }
  #services-content-wrapper #introduction {
    margin: 0px auto;
    padding: 272px 20px 10px;
    width: 100%;
    max-width: 960px;
    height: 100%;
    min-height: calc(100vh - 10px);
    color: white;
    text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3); }
    #services-content-wrapper #introduction h1 {
      font-size: 48px;
      margin-bottom: 40px; }
    #services-content-wrapper #introduction p {
      font-size: 22px;
      font-weight: bold;
      margin: 20px 0px; }
    #services-content-wrapper #introduction .explore-button {
      background: white;
      background-image: linear-gradient(to bottom, rgba(254, 123, 0, 0.05), #fbfbf8, rgba(254, 123, 0, 0.05));
      border-radius: 30px;
      -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      color: #2e2525;
      font-size: 30px;
      margin: 60px auto 40px;
      max-width: 160px;
      padding: 10px 0px;
      width: 100%; }
      #services-content-wrapper #introduction .explore-button:hover {
        color: #e21214; }
    #services-content-wrapper #introduction .explore-button-heat {
      background: -webkit-gradient(linear, left top, left bottom, from(#fbfbf8), to(#fddbbc));
      background: linear-gradient(to bottom, #fbfbf8, #fddbbc); }
      #services-content-wrapper #introduction .explore-button-heat:hover {
        background: -webkit-gradient(linear, left top, left bottom, from(#fddbbc), to(#fbfbf8));
        background: linear-gradient(to bottom, #fddbbc, #fbfbf8); }
    #services-content-wrapper #introduction .explore-button-cool {
      background: -webkit-gradient(linear, left top, left bottom, from(#fbfbf8), to(#95c9d6));
      background: linear-gradient(to bottom, #fbfbf8, #95c9d6); }
      #services-content-wrapper #introduction .explore-button-cool:hover {
        background: -webkit-gradient(linear, left top, left bottom, from(#95c9d6), to(#fbfbf8));
        background: linear-gradient(to bottom, #95c9d6, #fbfbf8); }
    #services-content-wrapper #introduction .explore-button-water-heat {
      background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(#93c3cf), to(#fddbbc));
      background: linear-gradient(to bottom, white, #93c3cf, #fddbbc); }
      #services-content-wrapper #introduction .explore-button-water-heat:hover {
        background: -webkit-gradient(linear, left top, left bottom, from(#fddbbc), color-stop(#93c3cf), to(white));
        background: linear-gradient(to bottom, #fddbbc, #93c3cf, white); }
    @media screen and (max-width: 1024px) {
      #services-content-wrapper #introduction {
        padding: 232px 10px 10px 35px; } }
    @media screen and (max-width: 768px) {
      #services-content-wrapper #introduction {
        padding: 232px 15px 10px 45px; } }
    @media screen and (max-width: 745px), screen and (max-height: 480px) {
      #services-content-wrapper #introduction {
        padding-top: 128.8px;
        min-height: calc(100vh - 200px); }
        #services-content-wrapper #introduction h1 {
          font-size: 32px;
          margin-bottom: 20px; }
        #services-content-wrapper #introduction p {
          font-size: 18px; }
        #services-content-wrapper #introduction .explore-button {
          margin: 20px auto 20px;
          max-width: 110px;
          font-size: 20px; } }
    @media screen and (max-width: 300px) {
      #services-content-wrapper #introduction {
        padding-top: 90px; } }
  #services-content-wrapper #bottom-overlay {
    display: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    padding: 5px 0px;
    background: linear-gradient(to top, black, #1F1F1F);
    z-index: 10; }
    #services-content-wrapper #bottom-overlay button {
      margin: 0px 8px;
      padding: 5px 0px;
      width: 110px;
      height: auto;
      background: -webkit-gradient(linear, left bottom, left top, from(#e21214), to(#f25e5f));
      background: linear-gradient(to top, #e21214, #f25e5f);
      color: white;
      font-weight: bold;
      font-size: 20px;
      border-radius: 1.0em;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden; }
      #services-content-wrapper #bottom-overlay button:active {
        background: -webkit-gradient(linear, left top, left bottom, from(#e21214), to(#b30e10));
        background: linear-gradient(to bottom, #e21214, #b30e10); }
    @media screen and (max-width: 570px), screen and (max-height: 480px) {
      #services-content-wrapper #bottom-overlay button {
        margin: 0px 4px;
        font-size: 18px; } }
  #services-content-wrapper #main-content-wrapper {
    width: 100%;
    background-color: #fbfbf8; }
    #services-content-wrapper #main-content-wrapper .seperator {
      width: 100%;
      height: 20px;
      border: unset; }
    #services-content-wrapper #main-content-wrapper .seperator-five-star-red {
      background-color: #e21214; }
    #services-content-wrapper #main-content-wrapper .seperator-thick-sky-blue {
      background: radial-gradient(#00445f, #006f8b); }
    #services-content-wrapper #main-content-wrapper .seperator-thick-water-warmth {
      background: radial-gradient(#0097be, #fe7b00); }
    #services-content-wrapper #main-content-wrapper #section-button-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin: 20px auto;
      width: 100%;
      max-width: 960px;
      height: 100%; }
      #services-content-wrapper #main-content-wrapper #section-button-container .sectionButton {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 30px;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: #333;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        height: 160px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 10px;
        padding: 10px;
        width: 100%; }
        #services-content-wrapper #main-content-wrapper #section-button-container .sectionButton img {
          display: block;
          width: 100%;
          max-width: 140px;
          height: 100%;
          max-height: 140px;
          border-radius: 50%;
          border: 4px solid #e21214;
          -webkit-box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2);
          overflow: hidden; }
        #services-content-wrapper #main-content-wrapper #section-button-container .sectionButton span {
          margin-left: 10px;
          width: 100%;
          max-width: 130px;
          font-weight: bold;
          font-size: 22px;
          text-align: left; }
      #services-content-wrapper #main-content-wrapper #section-button-container .section-button-six {
        max-width: 300px;
        background: linear-gradient(to bottom, rgba(254, 123, 0, 0.05), #fbfbf8, rgba(254, 123, 0, 0.05)); }
        #services-content-wrapper #main-content-wrapper #section-button-container .section-button-six:hover {
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(254, 123, 0, 0.7)), color-stop(white), to(rgba(254, 123, 0, 0.7)));
          background: linear-gradient(to bottom, rgba(254, 123, 0, 0.7), white, rgba(254, 123, 0, 0.7));
          color: #e21214; }
      #services-content-wrapper #main-content-wrapper #section-button-container .section-button-four {
        max-width: 450px;
        background: linear-gradient(to bottom, #fbfbf8, rgba(0, 111, 139, 0.1)); }
        #services-content-wrapper #main-content-wrapper #section-button-container .section-button-four:hover {
          background-color: #006f8b;
          color: #00445f; }
      #services-content-wrapper #main-content-wrapper #section-button-container .section-button-water-heat {
        max-width: 450px;
        background: linear-gradient(to bottom, #fbfbf8, rgba(0, 111, 139, 0.1)); }
        #services-content-wrapper #main-content-wrapper #section-button-container .section-button-water-heat:hover {
          background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(rgba(0, 111, 139, 0.1)), to(rgba(254, 123, 0, 0.4)));
          background: linear-gradient(to bottom, white, rgba(0, 111, 139, 0.1), rgba(254, 123, 0, 0.4));
          color: #006f8b; }
      @media screen and (max-width: 745px), screen and (max-height: 480px) {
        #services-content-wrapper #main-content-wrapper #section-button-container .sectionButton {
          max-width: 230px;
          height: 110px; }
          #services-content-wrapper #main-content-wrapper #section-button-container .sectionButton img {
            max-width: 70px;
            max-height: 70px; }
          #services-content-wrapper #main-content-wrapper #section-button-container .sectionButton span {
            font-size: 18px; } }
      @media screen and (max-width: 270px) {
        #services-content-wrapper #main-content-wrapper #section-button-container .sectionButton span {
          font-size: 14px; } }
    #services-content-wrapper #main-content-wrapper section {
      margin: 0px auto;
      padding: 40px 0px;
      width: 100%;
      max-width: 960px; }
      #services-content-wrapper #main-content-wrapper section a:hover {
        color: #e21214;
        text-decoration: underline; }
      #services-content-wrapper #main-content-wrapper section h2 {
        padding: 0px 10px;
        margin: 0px auto 20px;
        font-size: 32px;
        text-align: center; }
        @media screen and (max-width: 570px), screen and (max-height: 480px) {
          #services-content-wrapper #main-content-wrapper section h2 {
            font-size: 26px; } }
      #services-content-wrapper #main-content-wrapper section h3 {
        font-size: 20px; }
      #services-content-wrapper #main-content-wrapper section h4 {
        margin-bottom: 20px;
        padding: 0px 20px;
        text-align: center;
        font-size: 20px;
        color: #737373; }
      #services-content-wrapper #main-content-wrapper section P {
        margin: 20px 0px;
        font-size: 20px; }
        #services-content-wrapper #main-content-wrapper section P img {
          display: block;
          margin: 20px 0px 0px;
          width: 100%;
          max-width: 960px;
          height: 100%;
          max-height: 480px; }
        #services-content-wrapper #main-content-wrapper section P img[src*="small-contentimage"] {
          width: 250px;
          margin-left: auto;
          margin-right: auto; }
      #services-content-wrapper #main-content-wrapper section ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        padding: 15px;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 30px; }
        #services-content-wrapper #main-content-wrapper section ul li {
          list-style-type: none;
          margin: 5px 0px;
          font-size: 18px; }
      #services-content-wrapper #main-content-wrapper section .portfolioLink {
        display: block;
        width: 100%;
        font-size: 14px;
        text-align: center; }
      #services-content-wrapper #main-content-wrapper section .italicized {
        font-style: italic; }
      @media screen and (max-width: 1024px) {
        #services-content-wrapper #main-content-wrapper section {
          padding: 20px 5px 20px 35px; }
          #services-content-wrapper #main-content-wrapper section h2, #services-content-wrapper #main-content-wrapper section h4 {
            padding: 0px; } }
      @media screen and (max-width: 570px), screen and (max-height: 480px) {
        #services-content-wrapper #main-content-wrapper section h2 {
          margin: 10px auto;
          text-align: left;
          font-size: 24px; }
        #services-content-wrapper #main-content-wrapper section h3 {
          font-size: 16px; }
        #services-content-wrapper #main-content-wrapper section h4 {
          margin: 10px auto;
          font-size: 16px; }
        #services-content-wrapper #main-content-wrapper section p {
          font-size: 18px; } }
    #services-content-wrapper #main-content-wrapper #county-select {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 100%;
      height: auto;
      min-height: 480px;
      background-color: black;
      border: 5px solid #333;
      border-radius: 30px;
      -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      overflow: hidden; }
      #services-content-wrapper #main-content-wrapper #county-select img {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        display: block;
        -o-object-fit: cover;
        object-fit: cover;
        z-index: 0; }
      #services-content-wrapper #main-content-wrapper #county-select .filter-black {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65); }
      #services-content-wrapper #main-content-wrapper #county-select button {
        position: relative;
        padding: 20px 30px;
        width: 100%;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        background: none;
        border: none;
        border-radius: unset;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: white;
        font-weight: bold;
        font-size: 30px;
        text-align: left;
        text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer; }
        #services-content-wrapper #main-content-wrapper #county-select button:hover {
          background-color: rgba(0, 0, 0, 0.8); }
      #services-content-wrapper #main-content-wrapper #county-select hr {
        position: relative;
        margin: 0px;
        padding: 0px;
        width: 100%;
        height: 1px;
        border: none;
        background-color: white; }
      #services-content-wrapper #main-content-wrapper #county-select ul {
        position: absolute;
        bottom: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex-line-pack: end;
        align-content: flex-end;
        padding: 20px;
        width: 100%;
        height: calc(100% - 90px);
        opacity: 0;
        pointer-events: none;
        z-index: 1; }
        #services-content-wrapper #main-content-wrapper #county-select ul li {
          margin: 10px;
          font-size: 20px;
          font-weight: bold;
          color: white;
          text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
          list-style: none; }
      @media screen and (max-width: 570px), screen and (max-height: 480px) {
        #services-content-wrapper #main-content-wrapper #county-select button {
          font-size: 24px; }
        #services-content-wrapper #main-content-wrapper #county-select ul li {
          margin: 5px;
          font-size: 16px; } }
      @media screen and (max-width: 430px) {
        #services-content-wrapper #main-content-wrapper #county-select button {
          padding: 10px;
          font-size: 20px; }
        #services-content-wrapper #main-content-wrapper #county-select ul li {
          font-size: 12px; } }

/* generators page */
.generators-bg {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.5))), url("./images/generators/Generac-Transfer-Switch-background-image.jpg");
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url("./images/generators/Generac-Transfer-Switch-background-image.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top; }

#header-banner {
  height: calc(100vh - 180px); }
  #header-banner #banner-image-overlay {
    position: absolute;
    bottom: 0px;
    z-index: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 10px;
    width: 100%;
    min-height: 20%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4); }
    #header-banner #banner-image-overlay h1 {
      color: White;
      text-shadow: 2px 2px 5px black;
      font-size: 86px; }
  @media screen and (max-width: 570px), screen and (max-height: 480px) {
    #header-banner {
      height: calc(100vh - 110px); }
      #header-banner #banner-image-overlay h1 {
        font-size: 56px; } }

#about-content-wrapper #description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0px;
  padding: 90px 10px;
  height: 300px;
  background-color: rgba(46, 37, 37, 0.6); }
  @media screen and (max-width: 770px) {
    #about-content-wrapper #description {
      height: 700px;
      margin-top: 0px;
      padding: 50px 10px; } }
  #about-content-wrapper #description h2 {
    text-align: center;
    font-size: 32px;
    color: #fbf8f8; }
  #about-content-wrapper #description a {
    color: #ffffff;
    text-decoration: none;
    text-shadow: 0px 0px 0 #e21214, -1px -1px 0 #e21214, 1px -1px 0 #e21214, -1px 1px 0 #e21214, 1px 1px 0 #e21214; }
    @media screen and (max-width: 770px) {
      #about-content-wrapper #description a br.md {
        display: inline;
        /*Enable br tags in screen width<=770px*/ } }
    #about-content-wrapper #description a:hover {
      text-decoration: underline; }
  #about-content-wrapper #description p {
    margin-top: 30px;
    max-width: 950px;
    text-align: center;
    font-size: 20px;
    color: #fbf8f8; }

#about-content-wrapper #video-area {
  padding: 40px;
  width: 100%;
  background-color: #e21214; }
  #about-content-wrapper #video-area #video-wrapper {
    margin: 0px auto;
    width: 100%;
    max-width: 968px;
    height: 100%;
    border: 4px solid #e21214; }
    #about-content-wrapper #video-area #video-wrapper video {
      display: block;
      width: 100%;
      outline: none; }
  @media screen and (max-width: 415px) {
    #about-content-wrapper #video-area {
      padding: 20px 0px;
      border: 0px; } }

#about-content-wrapper #mission-statement {
  margin: 80px auto;
  padding: 0px 10px;
  text-align: center;
  background: #fbfbf8; }
  #about-content-wrapper #mission-statement h2 {
    color: #2e2525;
    margin: 20px; }
  #about-content-wrapper #mission-statement p {
    font-size: 20px;
    color: #3a3131; }

#service-button-content {
  background-color: rgba(255, 255, 255, 0.7);
  max-width: 100%; }
  #service-button-content #service-button-header-wrapper {
    margin: 0px auto 0px;
    margin-bottom: 10px;
    max-width: 960px;
    padding: 147px 20px 0px;
    font-size: 20px;
    text-align: center; }
    #service-button-content #service-button-header-wrapper h2 {
      display: inline;
      opacity: 0;
      color: #2e2525;
      text-shadow: 1px 2px 2px white;
      line-height: 1.35em; }
    #service-button-content #service-button-header-wrapper h3 {
      margin-top: 50px;
      font-size: 24px; }
    #service-button-content #service-button-header-wrapper p {
      margin-top: 20px; }
    @media screen and (min-width: 565px) {
      #service-button-content #service-button-header-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 24px;
        padding: 247px 20px 0px; } }
    #service-button-content #service-button-header-wrapper .buttonContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      #service-button-content #service-button-header-wrapper .buttonContainer .buttonlink {
        display: block;
        margin: 30px;
        width: 400px;
        max-width: 450px;
        height: 100px;
        background-color: #e21214;
        -webkit-box-shadow: 0px 2px 5px black;
        box-shadow: 0px 2px 5px black;
        border-radius: 30px;
        color: white;
        font-size: 24px;
        text-decoration: none;
        text-align: center;
        line-height: 100px; }
        @media screen and (max-width: 420px) {
          #service-button-content #service-button-header-wrapper .buttonContainer .buttonlink {
            font-size: 24px; } }
        @media screen and (max-width: 250px) {
          #service-button-content #service-button-header-wrapper .buttonContainer .buttonlink {
            font-size: 1em; } }
      #service-button-content #service-button-header-wrapper .buttonContainer a.buttonlink:hover {
        background-color: rgba(39, 39, 39, 0.9); }
  #service-button-content .serviceCategory {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 20px auto;
    padding: 0px 20px;
    max-width: 960px;
    min-height: 170px;
    overflow: auto; }
    @media screen and (max-width: 415px) {
      #service-button-content .serviceCategory {
        margin: 0px auto; } }
    #service-button-content .serviceCategory .categoryHeaderWrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 0px auto;
      padding: 60px 20px;
      width: 100%;
      height: 150px;
      opacity: 0;
      background-color: white;
      color: #2e2525;
      -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      border: 10px solid #e21214;
      cursor: pointer; }
      #service-button-content .serviceCategory .categoryHeaderWrapper h3 {
        display: block;
        font-size: 1.5em;
        padding: 5px 0px;
        border-radius: 10px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); }
    #service-button-content .serviceCategory ul.serviceList {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      padding: 0px;
      width: 100%;
      height: 0px;
      overflow: hidden; }
      #service-button-content .serviceCategory ul.serviceList img {
        display: none; }
      #service-button-content .serviceCategory ul.serviceList li {
        -webkit-box-flex: .5;
        -ms-flex: 0.5 1 calc((100% / 3) - 10px);
        flex: 0.5 1 calc((100% / 3) - 10px);
        padding: 15px;
        margin: 20px auto;
        min-width: 160px;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
        color: #2e2525;
        font-size: .80em;
        font-weight: bold;
        list-style-type: none;
        text-align: center;
        cursor: pointer; }
    #service-button-content .serviceCategory ul.serviceInfoContainer {
      margin: 0px auto;
      padding: 0px 20px 20px 20px;
      width: 100%;
      height: 100%;
      background: #f3f0f0;
      border-radius: 3px 3px 20px 20px;
      overflow: hidden; }
      #service-button-content .serviceCategory ul.serviceInfoContainer li {
        height: 0px;
        width: 100%;
        overflow: hidden; }
        #service-button-content .serviceCategory ul.serviceInfoContainer li p {
          font-size: 1.2em;
          font-weight: bold;
          margin-bottom: 12px; }
        #service-button-content .serviceCategory ul.serviceInfoContainer li h2 {
          text-align: center;
          color: #e21214; }
        #service-button-content .serviceCategory ul.serviceInfoContainer li .outlinkContainer {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
          #service-button-content .serviceCategory ul.serviceInfoContainer li .outlinkContainer .outlink {
            display: block;
            margin: 30px;
            width: 400px;
            max-width: 450px;
            height: 75px;
            background-color: whitesmoke;
            -webkit-box-shadow: 0px 2px 5px black;
            box-shadow: 0px 2px 5px black;
            border-radius: 10px;
            border-color: black;
            color: #2e2525;
            font-size: 24px;
            text-decoration: none;
            text-align: center;
            line-height: 75px; }
    #service-button-content .serviceCategory .dividerLine {
      display: block;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      height: 1px;
      margin: 10px 0px 15px;
      background-color: rgba(46, 37, 37, 0.4); }
    #service-button-content .serviceCategory .scrollUp {
      display: block;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      height: 30px;
      margin-top: 10px;
      padding: 0px;
      border-radius: 10px;
      background-color: white;
      color: white;
      font-size: 1em;
      font-weight: bold;
      line-height: 30px;
      text-align: center; }
      @media screen and (min-height: 821px) {
        #service-button-content .serviceCategory .scrollUp {
          display: none; } }
  @media screen and (min-width: 340px) {
    #service-button-content .serviceCategory .categoryHeaderWrapper {
      padding: 40px 20px; } }
  @media screen and (min-width: 340px) and (min-width: 555px) {
    #service-button-content .serviceCategory .categoryHeaderWrapper {
      padding: 20px 20px; } }
  @media screen and (min-width: 340px) {
    #service-button-content .serviceCategory ul.serviceList img {
      display: block;
      margin: 10px auto 0px;
      border: 4px solid white;
      width: 100%;
      max-width: 908px;
      max-height: 200px; }
    #service-button-content .serviceCategory ul.serviceList li {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 calc((100% / 4) - 10px);
      flex: 0 1 calc((100% / 4) - 10px);
      font-size: 1em;
      white-space: nowrap; } }

@media screen and (min-width: 340px) {
  .serviceCategory .categoryHeaderWrapper {
    padding: 40px 20px; } }
  @media screen and (min-width: 340px) and (min-width: 555px) {
    .serviceCategory .categoryHeaderWrapper {
      padding: 20px 20px; } }

@media screen and (min-width: 340px) {
  .serviceCategory ul.serviceList img {
    display: block;
    margin: 10px auto 0px;
    border: 4px solid white;
    width: 100%;
    max-width: 908px;
    max-height: 200px; }
  .serviceCategory ul.serviceList li {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 calc((100% / 4) - 10px);
    flex: 0 1 calc((100% / 4) - 10px);
    font-size: 1em;
    white-space: nowrap; } }

#gallery-content-wrapper {
  background-color: whitesmoke;
  max-width: 100%; }
  #gallery-content-wrapper #intro-section {
    position: relative;
    margin: 0px auto;
    padding: 342px 0px 20px; }
    #gallery-content-wrapper #intro-section #gallery-banner {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0; }
    #gallery-content-wrapper #intro-section h1 {
      position: relative;
      font-size: 48px;
      font-weight: bold;
      color: white;
      text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
      margin: 0px auto 20px;
      padding: 0px 30px; }
    #gallery-content-wrapper #intro-section p {
      position: relative;
      padding: 0px 20px;
      color: whitesmoke;
      text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
      font-weight: bold;
      font-size: 22px; }
    #gallery-content-wrapper #intro-section #gallery-prompt {
      position: relative;
      margin: 40px auto 0px;
      text-align: center;
      color: white;
      text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3); }
    @media screen and (max-width: 745px), screen and (max-height: 480px) {
      #gallery-content-wrapper #intro-section {
        padding-top: 181px; }
        #gallery-content-wrapper #intro-section h1 {
          font-size: 30px; }
        #gallery-content-wrapper #intro-section p {
          font-size: 16px; } }
  #gallery-content-wrapper #expanded-image-overlay {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    z-index: 5; }
    #gallery-content-wrapper #expanded-image-overlay #image-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: auto;
      padding: 0px 10px;
      width: 100%;
      max-width: 960px;
      height: auto;
      z-index: 6; }
      #gallery-content-wrapper #expanded-image-overlay #image-container #prompt,
      #gallery-content-wrapper #expanded-image-overlay #image-container #description {
        color: white;
        text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin: 0px auto; }
      #gallery-content-wrapper #expanded-image-overlay #image-container img {
        margin: 20px auto;
        width: 100%;
        -o-object-fit: contain;
        object-fit: contain; }
      #gallery-content-wrapper #expanded-image-overlay #image-container #button-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 20px; }
        #gallery-content-wrapper #expanded-image-overlay #image-container #button-section button {
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          margin: 0px 10px;
          padding: 8px;
          border-radius: 15px;
          width: 72px;
          height: auto;
          font-size: 18px; }
    #gallery-content-wrapper #expanded-image-overlay #background-filter {
      position: absolute;
      top: 0px;
      background-color: rgba(0, 0, 0, 0.85);
      width: 100%;
      height: 100%;
      z-index: 0; }
  #gallery-content-wrapper hr {
    height: 10px;
    background-color: #e21214;
    width: 100%;
    border: none; }
  #gallery-content-wrapper #thumbnail-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0px auto;
    padding: 20px 6px; }
    #gallery-content-wrapper #thumbnail-grid img {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      margin: 6px;
      width: 100%;
      height: 100%;
      max-width: 225px;
      max-height: 225px;
      cursor: pointer; }
      #gallery-content-wrapper #thumbnail-grid img:hover, #gallery-content-wrapper #thumbnail-grid img.active {
        margin: 2px;
        border: 4px solid #e21214;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }

@media screen and (min-width: 960px) {
  #portfolio-content #portfolio-modals .modal .close {
    margin: 20px calc(((100vw - 980px)/2) + 60px) 20px 0px; }
  #portfolio-content #portfolio-modals .modal .portfolioModal {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr; }
    #portfolio-content #portfolio-modals .modal .portfolioModal .prevBtn {
      grid-column: 1;
      grid-row: 1; }
    #portfolio-content #portfolio-modals .modal .portfolioModal img {
      grid-column: 2;
      grid-row: 1;
      width: auto; }
    #portfolio-content #portfolio-modals .modal .portfolioModal .nextBtn {
      grid-column: 3;
      grid-row: 1; }
    #portfolio-content #portfolio-modals .modal .portfolioModal p {
      grid-column: 1 / span 3;
      grid-row: 2;
      font-size: 26px;
      color: white;
      font-weight: bold;
      cursor: auto; } }

#contact-banner {
  position: fixed;
  top: 0px;
  height: 100vh;
  z-index: -1; }

#contact-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 225px;
  padding: 0px 20px;
  width: 100%; }
  @media screen and (max-width: 745px), screen and (max-height: 480px) {
    #contact-content-wrapper {
      margin-top: 125px; } }
  @media screen and (max-width: 300px) {
    #contact-content-wrapper {
      margin-top: 70px; } }
  #contact-content-wrapper h1 {
    margin: 20px 0px;
    color: white;
    text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 48px; }
    @media screen and (max-width: 745px), screen and (max-height: 480px) {
      #contact-content-wrapper h1 {
        margin: 10px 0px;
        font-size: 32px; } }
  #contact-content-wrapper p {
    margin: 10px 0px;
    color: white;
    text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 30px;
    font-weight: bold; }
    @media screen and (max-width: 745px), screen and (max-height: 480px) {
      #contact-content-wrapper p {
        font-size: 20px; } }
  #contact-content-wrapper #contact-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    margin-top: 20px; }
    #contact-content-wrapper #contact-area #options-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding: 0px 10px;
      width: 50%; }
      #contact-content-wrapper #contact-area #options-container .option {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 40px;
        width: 100%; }
        #contact-content-wrapper #contact-area #options-container .option a {
          text-decoration: none;
          cursor: pointer; }
          #contact-content-wrapper #contact-area #options-container .option a img {
            display: block;
            margin-right: 20px;
            width: 100%;
            height: 100%;
            max-width: 60px;
            max-height: 60px; }
        #contact-content-wrapper #contact-area #options-container .option p {
          margin: 20px 0px;
          width: 300px;
          font-size: 24px;
          font-weight: bold;
          text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
          font-weight: bold;
          color: white; }
          @media screen and (max-width: 240px) {
            #contact-content-wrapper #contact-area #options-container .option p {
              width: 100%; } }
        @media screen and (max-width: 745px), screen and (max-height: 480px) {
          #contact-content-wrapper #contact-area #options-container .option {
            margin-bottom: 20px; }
            #contact-content-wrapper #contact-area #options-container .option a img {
              max-width: 40px;
              max-height: 40px; }
            #contact-content-wrapper #contact-area #options-container .option a p {
              margin: 20px 0px;
              font-size: 20px;
              width: 250px; } }
        @media screen and (max-width: 699px) {
          #contact-content-wrapper #contact-area #options-container .option a img {
            margin: 0px 20px; } }
        @media screen and (max-width: 580px) {
          #contact-content-wrapper #contact-area #options-container .option a {
            width: 100%; }
            #contact-content-wrapper #contact-area #options-container .option a img {
              margin: 0px auto; }
            #contact-content-wrapper #contact-area #options-container .option a p {
              width: 100%;
              text-align: center; }
            #contact-content-wrapper #contact-area #options-container .option a #email-prompt {
              margin-bottom: 0px; } }
    #contact-content-wrapper #contact-area form {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0px 20px;
      width: 50%; }
      #contact-content-wrapper #contact-area form .inputData {
        margin: 10px 0px;
        padding: 20px;
        width: 100%;
        max-width: 480px;
        height: 40px;
        border: solid 2px white;
        border-radius: 30px;
        background-color: white;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: #2e2525;
        font-weight: bold; }
        #contact-content-wrapper #contact-area form .inputData:focus:valid, #contact-content-wrapper #contact-area form .inputData.filled:valid {
          border: solid 2px #00DC00; }
        #contact-content-wrapper #contact-area form .inputData:focus:invalid, #contact-content-wrapper #contact-area form .inputData.filled:invalid {
          border: solid 2px red; }
      #contact-content-wrapper #contact-area form textarea.inputData {
        height: 200px;
        resize: none; }
      #contact-content-wrapper #contact-area form #submit-button {
        margin: 10px 0px;
        padding: 0px 20px;
        height: 30px;
        line-height: 29px;
        border: unset;
        border-radius: 30px;
        background: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.05));
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: #2e2525;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer; }
        #contact-content-wrapper #contact-area form #submit-button:active {
          background: linear-gradient(to top, #fbfbf8, #D0D0D0); }
        #contact-content-wrapper #contact-area form #submit-button:disabled {
          color: #BBB; }
    @media screen and (max-width: 500px) {
      #contact-content-wrapper #contact-area #options-container,
      #contact-content-wrapper #contact-area form {
        width: 100%; }
      #contact-content-wrapper #contact-area #options-container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        #contact-content-wrapper #contact-area #options-container .option p {
          text-align: center; } }
  #contact-content-wrapper hr {
    margin: 20px 0px;
    width: 100%;
    color: white;
    -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  #contact-content-wrapper #address-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 20px;
    width: 100%; }
    #contact-content-wrapper #address-area p {
      -webkit-box-flex: 40%;
      -ms-flex: 40%;
      flex: 40%;
      margin: 0px 0px 10px 0px; }
    #contact-content-wrapper #address-area iframe {
      -webkit-box-flex: 60%;
      -ms-flex: 60%;
      flex: 60%;
      width: 100%;
      height: 480px;
      border: 4px solid #e21214;
      -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }

/* -------------------------------- REVEAL SUB-MENU CONTACT FORM  ----------------------- */
#schedule-form-reveal {
  position: fixed;
  top: 0px;
  padding-top: 80px;
  display: block;
  height: 180px;
  width: 100vw;
  background: radial-gradient(#dd373a, #e21214);
  z-index: 1; }
  #schedule-form-reveal a {
    color: white;
    padding: 0px 0px 0px 50px; }
    #schedule-form-reveal a:hover {
      text-decoration: underline; }
  #schedule-form-reveal .phone-icon {
    padding: 0px; }
  #schedule-form-reveal h1 {
    color: white;
    font-family: ddchardware-regular;
    font-weight: 900;
    margin-bottom: 5px;
    text-shadow: 2px 2px #2e2525; }
    @media (max-width: 855px) {
      #schedule-form-reveal h1 {
        display: none; } }
  @media (max-width: 855px) {
    #schedule-form-reveal {
      z-index: 1; } }
  @media (max-width: 745px) {
    #schedule-form-reveal {
      padding-top: 60px;
      height: 150px; } }

.reveal-form-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 100%;
  margin: auto;
  background-color: none; }

fieldset {
  border: 0; }

.reveal-form-container {
  width: 855px;
  /*background-color: rgba(46, 37, 37, .05);*/
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-shadow: 0 1px 1px rgba 0, 0, 0, 0.075 inset;
  box-shadow: 0 1px 1px rgba 0, 0, 0, 0.075 inset; }
  .reveal-form-container input {
    color: #bebebe;
    background-color: rgba(46, 37, 37, 0.1);
    border: 1px solid rgba(46, 37, 37, 0.4);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 550;
    letter-spacing: 1px;
    padding: 5px;
    text-shadow: 1px 1px #2e2525;
    width: 175px; }
    .reveal-form-container input label {
      z-index: 0; }
  .reveal-form-container .reveal-submit {
    color: white;
    background: radial-gradient(#00445f, #006f8b);
    border: 5px solid white;
    letter-spacing: 2px;
    padding: 7px;
    font-weight: 900;
    cursor: pointer; }
    .reveal-form-container .reveal-submit:hover {
      background: radial-gradient(#01c9fc, #00445f); }
  @media (max-width: 855px) {
    .reveal-form-container {
      width: 100%; }
      .reveal-form-container input {
        display: none; } }
  .reveal-form-container .inputData:focus:valid, .reveal-form-container .inputData.filled:valid {
    border: solid 2px #00DC00; }
  .reveal-form-container .inputData:focus:invalid, .reveal-form-container .inputData.filled:invalid {
    border: solid 4px #ff0022; }

.phone-link-mobile {
  display: none; }
  .phone-link-mobile img {
    display: block;
    margin-right: 20px;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px; }
  @media (max-width: 855px) {
    .phone-link-mobile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: .5em 1.0em;
      font-size: 40px; }
      .phone-link-mobile a {
        color: white;
        text-decoration: underline;
        text-shadow: 1px 1px #2e2525; } }
  @media (max-width: 414px) {
    .phone-link-mobile {
      padding: .75em .25em; }
      .phone-link-mobile a {
        font-size: 25px; }
      .phone-link-mobile img {
        max-width: 23px;
        max-height: 23px; } }

.anchor-contact {
  display: block;
  padding-top: 90px;
  margin-top: -90px; }
  @media screen and (max-width: 745px) {
    .anchor-contact {
      padding-top: 60px;
      margin-top: -60px; } }

.sidenav-selector {
  position: fixed;
  height: 100vh;
  width: 5%;
  top: 70px;
  z-index: 1;
  background-color: rgba(226, 18, 20, 0.4); }
  .sidenav-selector a {
    position: relative;
    font-size: 50px;
    top: 50%;
    color: white;
    margin-left: 35%;
    cursor: pointer; }
  @media screen and (max-width: 414px) {
    .sidenav-selector {
      width: 7%; }
      .sidenav-selector a {
        margin-left: 12%; } }

.sidenav-menu {
  position: fixed;
  display: inline-block;
  max-width: 175px;
  height: 100%;
  width: 0;
  z-index: 1;
  top: 0px;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(#e21214), to(rgba(226, 18, 20, 0.4)));
  background: linear-gradient(to right, #e21214, rgba(226, 18, 20, 0.4));
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 13%;
  /* ---------------- Side nav for less than desktop
    @media screen and (max-width: 1240px) {
        button { max-width:20px;
        }
    } ----------------*/ }
  .sidenav-menu h2 {
    color: white;
    background: #2e2525;
    border-radius: 5px 0px 0px 5px;
    margin-left: 9px;
    margin-bottom: 15px;
    padding: 4px 4px 4px 10px;
    font-size: 21px;
    white-space: nowrap; }
  .sidenav-menu li {
    margin: 10px;
    font-size: 20px;
    height: 78px;
    width: 100%;
    max-width: 150px;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #fbfbf8;
    text-align: center;
    cursor: pointer; }
    .sidenav-menu li:hover {
      background-color: rgba(46, 37, 37, 0.4);
      color: #e21214;
      border: 5px solid rgba(226, 18, 20, 0.4); }
    .sidenav-menu li a {
      display: block;
      height: 100%;
      padding: 20px 10px;
      color: black;
      margin: 0px; }
  .sidenav-menu .closebtn {
    margin-left: 80%;
    padding-bottom: 15%;
    font-size: 50px;
    height: 100%;
    cursor: pointer; }
  @media screen and (max-width: 1024px) {
    .sidenav-menu {
      padding-top: 45%; } }
  @media screen and (max-width: 414px) {
    .sidenav-menu {
      padding-top: 20%; } }
  @media screen and (max-width: 360px) {
    .sidenav-menu {
      padding-top: 23%; }
      .sidenav-menu li {
        height: 68px; }
        .sidenav-menu li button {
          font-size: 20px;
          height: 68px;
          margin: 10px; } }
