@media screen and (min-width: 960px) {
    #portfolio-content {
        #portfolio-modals {
            .modal {
                .close {
                    margin: 20px calc(((100vw - 980px)/2) + 60px) 20px 0px;
                }

                .portfolioModal {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: 1fr 1fr;

                    .prevBtn {
                        grid-column: 1;
                        grid-row: 1;
                    }

                    img {
                        grid-column: 2;
                        grid-row: 1;
                        width: auto;
                    }

                    .nextBtn {
                        grid-column: 3;
                        grid-row: 1;
                    }

                    p {

                        grid-column: 1 / span 3;
                        grid-row: 2;
                        font-size: 26px;
                        color: white;
                        font-weight: bold;
                        cursor: auto;
                    }
                }
            }
        }
    }
}