.anchor-contact {
    display: block;
    padding-top: 90px;
    margin-top: -90px;

    @media screen and (max-width: $logoBP2) {
        padding-top: 60px;
        margin-top: -60px;
    }
}

// - Side Navigation Section -


.sidenav-selector {
    position: fixed;
    height: 100vh;
    width: 5%;
    top: 70px;
    z-index: 1;
    background-color: $fspsRedlow;

    a {
        position: relative;
        font-size: 50px;
        top: 50%;
        color: white;
        margin-left: 35%;

        cursor: pointer;
    }

    @media screen and (max-width: $iPhone678) {
        width: 7%;
            a {
                margin-left: 12%;
            }
    }
}


.sidenav-menu {
    position: fixed;
    display: inline-block;
    max-width: 175px;
    height: 100%;
    width: 0;
    z-index: 1;
    top: 0px;
    left: 0;
    background: -webkit-gradient(linear, left top, right top, from($fspsRed), to($fspsRedlow));
    background: linear-gradient(to right, $fspsRed, $fspsRedlow);
    overflow-x: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-top: 13%;

    h2 {
        color: white;
        background: $fspsCharcoal;
        border-radius: 5px 0px 0px 5px; 
        margin-left: 9px;
        margin-bottom: 15px;
        padding: 4px 4px 4px 10px;
        font-size: 21px;
        white-space: nowrap;
    }


    li {
        margin: 10px;
        font-size: 20px;
        height:78px;
        width: 100%;
        max-width: 150px;
        border: 3px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        background-color: $TNWhite;
        text-align: center;

        cursor: pointer;
    
        &:hover {
            background-color: $fspsCharcoallow;
            color: $fspsRed;
            border: 5px solid $fspsRedlow;
        }

        a {
            display: block;
            height: 100%;
            padding: 20px 10px;
            color: black;
            margin: 0px;
        }

    }

    .closebtn {
        margin-left: 80%;
        padding-bottom: 15%;
        font-size: 50px;
        height: 100%;

        cursor: pointer;
    }

    /* ---------------- Side nav for less than desktop
    @media screen and (max-width: 1240px) {
        button { max-width:20px;
        }
    } ----------------*/


    @media screen and (max-width: $iPadPro) {
        padding-top: 45%;
    }
    @media screen and (max-width: $iPhone678) {
        padding-top: 20%;
    }
    @media screen and (max-width: $GalaxyS5) {
        padding-top: 23%;
        li {
            height: 68px;
            button {
                font-size: 20px;
                height:68px;
                margin: 10px;
            }
        }
    }
}